import React from 'react'
import { useEffect, useState } from 'react';
import call from "../../../../assets/images/call.svg";
import { Pagination } from '../../../common/pagination/pagination';

import map from "../../../../assets/images/map.svg";
import envelop from "../../../../assets/images/envelop.svg";
import InputMask from 'react-input-mask';
import { Footer } from '../../../common/footer/footer';
import { uploadURL, NEW_URL } from '../../../../constants/applicationConstants.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'
import { baseURL } from '../../../../constants/applicationConstants.js';
import moment from 'moment';
import Sidebar from "../sidebar/sidebar.js";

import axios from 'axios';

export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    formData,
    onInputChange,
    formError,
    onTouchHandler,
    onDeleteButtonClick,
    onSubmitButtonClick,
    onEditButtonClick,
    formSubmitButtonDisable,onPaginationButtonClick,
    expertData,
    onViewProfieClick,
    // meta
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const [posts, setPosts] = useState([]);
  const [meta, setMeta] = useState({});
  const [totalPosts, setTotalPosts] = useState(0);
  const [fetch, setFetch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(0);
  const [page, setPage] = useState(1);

  const planDataSet = [
    {
      "label": "Active Without Payment",
      "value": "Active Without Payment"
    },
    {
      "label": "Suspended",
      "value": "Suspended"
    },
    {
      "label": "Cancelled",
      "value": "Cancelled"
    }
  ]

  const planDataSet1 = [
    
    {
      "label": "Expert",
      "value": "expert"
    },
    {
      "label": "Institute",
      "value": "institute"
    }
  ]



  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };



  // {t("PRINT.TITLE")}
  return (
    <>
      <section class="entiredashboard admindashboard">

        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
              <div class="myexpertnav martop60">
                      <ul>
                        <li class="active"><Link to={''}>Expert</Link></li>
                        <li><Link to={''}>Institute</Link></li>
                      </ul>
                    </div>
              <div className="myexpertformarea searcharea martop40">
           
                    <div className="input-container form-group">
                      <input
                        type="text"
                        className="form-control input"
                        placeholder=" "
                        name="firstName"
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        value={formData.firstName}
                      />
                      <div className="placeholder">firstName</div>
                    </div>
                  
                    <div className="input-container form-group">
                      <input
                        type="text"
                        className="form-control input"
                        placeholder=" "
                        name="lastName"
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        value={formData.lastName}
                      />
                      <div className="placeholder">lastName</div>
                    </div>
                
                    <div className="input-container form-group">
                      <select
                        className="input form-control form-select"
                        placeholder=" "
                        name="status"
                        value={formData.status}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                      >
                                            <option value="">Select</option>

                        {planDataSet?.map((item) => {
                          <option></option>
                          return (
                            <>
                              <option  value={item.value} selected={item.value === formData.subCategory}>{item.label}</option>
                            </>
                          )
                        })}
                      </select>
                      <div className="placeholder">Status</div>
                    </div>
                    <div className="input-container form-group">
                      <select
                        className="input form-control form-select"
                        placeholder=" "
                        name="type"
                        value={formData.type}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                      >
                                            <option value="">Select</option>

                        {planDataSet1?.map((item) => {
                          <option></option>
                          return (
                            <>
                              <option  value={item.value} selected={item.value === formData.subCategory}>{item.label}</option>
                            </>
                          )
                        })}
                      </select>
                      <div className="placeholder">Type</div>
                    </div>

                <div className="input-container form-group"><button type="submit" className="ctaonebutton"  disabled={formSubmitButtonDisable} onClick={() => onSubmitButtonClick()}>Submit</button></div>
              </div>
</div>
              <div className="expertsection martop30 mb-5">
               

                  {searchLoading === true && <div className="loadingResult"><h4>Loading</h4></div>}
                  <div className="searchdetails searchdetalsen">
                    <div className="searchdetailsdb">
                     
                      {searchLoading === true && <div className="loadingResult"><h4>Loading</h4></div>}
                  {
                    expertData.length > 0 ?
                      expertData?.map(data => { return  (
                        <div className="suspandedclass">
                 <h2>Name {data?.expert? data?.expert?.firstName : data?.institute?.name}</h2>
                 <h5>Payment due since: {moment(data?.endDate).format('DD-MM-YYYY')}</h5>
                 <a href="" className="ctaonebutton">View Profile</a>
<p className="mt-5 text-left">Current status</p>
                 <button className="cdbutton mt-3">Resend invoice with payment link</button>
                 <button className="cdbutton posab">{data?.status}</button>
               </div>
                      )  
                      }) :
                       <div className="opacity0">{meta?.totalPages ? t("SEARCHEXPERT.SEARCHRESULTNOTFOUND") : 'Search for expert'}</div>
                  }
                      </div>
                  </div>
                  {/* {searchLoading === true && <div className="loadingResult"><h4>Loading</h4></div>}
                  {
                    expertData.length > 0 ?
                      expertData?.map(data => {
                        return <div className="expertinner">
                          <div className="expertuser">

                            <p>Question : {data?.question}</p>
                           Created At : {moment(data?.createdAt).format('DD-MM-YYYY')}
                          </div>
                          <button onClick={() => onEditButtonClick(data?.askExpertQuestionId)}>Edit</button>
                          <button onClick={() => onDeleteButtonClick(data?.askExpertQuestionId)} >delete</button>


                        </div>
                      }) :
                      <div className="opacity1">{meta?.totalPages ? t("SEARCHEXPERT.SEARCHRESULTNOTFOUND") : 'Search for expert'}</div>
                  } */}
               

                {expertData?.length > 0 && <Pagination
            limit={10}
            totalCount={expertData?.meta?.totalCount}
            onPaginationButtonClick={onPaginationButtonClick}
          />}
              </div>
            </div>
          </div>

        </div>


        
      </section><Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;