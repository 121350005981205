import React, { useEffect } from 'react'
import testicomma from "../../../../assets/images/testicomma.svg";
import testicommainvert from "../../../../assets/images/testicommainvert.svg";
import { Footer } from '../../../common/footer/footer';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const GlobalMainSearchJsx = () => {
  useEffect(() => {
    AOS.init();
  }, [])


  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="homecustom">
     tt
       <Footer />
      </section>
    </>

  );
};

export default GlobalMainSearchJsx;