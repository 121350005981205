import React, { useState, useEffect, useRef } from 'react'
import InputMask from 'react-input-mask';
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SECRET_KEY } from "../../../constants/applicationConstants";
import background_of_getintouch from "../../../assets/expertppvimage/background_of_getintouch.svg";
import email_icon from "../../../assets/expertppvimage/email_icon.svg";
import phone_number_icon from "../../../assets/expertppvimage/phone_number_icon.svg";
import name_icon from "../../../assets/expertppvimage/name_icon.svg";
import { useTranslation } from 'react-i18next';

export const GetInTouchSection = (props) => {
    const { t } = useTranslation();

    const {
        profileData,
        onInputChangeHandler,
        onTouchHandler,
        userData,
        formError,
        onSendMessageClick,
        formSubmitButtonDisable
    } = props;
    const [recapchaVerified, setRecaptchaVerified] = useState(false);
    const onChange = (value) => {
        if (value) {
            setRecaptchaVerified(true);
        }
    }

    let flowName = window.location.href.includes('expertPpv')
    const recaptchaRef = useRef();

    return <section className="expertformppinew">
        <div className="container">
            
            <div className="row">
                <div className="col-lg-6 col-sm-6 nopadright">
                    <div className="getintouchimage">
                        <img src={background_of_getintouch} />
                    </div>
                </div>
                <div className="col-lg-6 col-sm-6 nopadleftt">
                <div className="expertformppiinnernew backpap"><h2>{t("GETINTOUCH.TEXT1")}</h2>
                <h5>{t("GETINTOUCH.TEXT2")}</h5>
                <div className="expertformppiinnerdisexperttouch">
                    <div className="mb-4 input-container form-group">
                        <input type="text" onChange={onInputChangeHandler} onBlur={onTouchHandler} name='name' value={userData.name} className="input form-control" placeholder=" " maxLength={60} />
                        <label className="placeholder">{t("GETINTOUCH.TEXT3")}</label>
                        <span className="text-danger">
                            {formError['name'] &&
                                (formError['name'] === "req"
                                    ? ''
                                    : 'Enter a valid Name')}
                        </span>
                        <span className="inputimage"><img src={name_icon} /></span>
                    </div>
                    <div className="input-container form-group">
                        <InputMask mask={'(999) 999 9999'} value={userData.phone} name="phone" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                            {(inputProps) => <input {...inputProps} type="tel" className="input form-control" placeholder=" " disableUnderline />}
                        </InputMask>
                        <div className="placeholder">{t("GETINTOUCH.TEXT4")}</div>
                        <span className="text-danger">
                            {formError['phone'] &&
                                (formError['phone'] === "req"
                                    ? ''
                                    : 'Enter a valid Phone')}
                        </span>
                        <span className="inputimage"><img src={phone_number_icon} /></span>
                    </div>

                </div>
                
                    <div className="mb-4 input-container form-group">
                        <input type="text" name='email' onChange={onInputChangeHandler} onBlur={onTouchHandler} value={userData.email} className="input form-control" placeholder=" " maxLength={60} />
                        <div className="placeholder">{t("GETINTOUCH.TEXT5")}</div>
                        <span className="text-danger">
                            {formError['email'] &&
                                (formError['email'] === "req"
                                    ? ''
                                    : 'Enter a valid Email')}
                        </span>
                        <span className="inputimage"><img src={email_icon} /></span>
                    </div>
                    <div className="input-container form-group">
                        <select className="input form-control form-select" onChange={onInputChangeHandler} name='phoneNumber' placeholder=" ">
                            <option value=''>{t("GETINTOUCH.TEXT6")}</option>
                            {
                                flowName ?
                                    profileData?.expertSpecializations?.map(cat => {
                                        return <option value={cat?.instituteCategoryId}>{cat?.parentInstitutionCategory}</option>
                                    })
                                    :
                                    profileData?.instituteCategories?.map(cat => {
                                        return <option value={cat.instituteCategoryId}>{cat.institutionCategory?.name}</option>
                                    })
                            }
                        </select>
                        <div className="placeholder">{t("GETINTOUCH.TEXT7")}</div>
                    </div>
                
              
                    <div className="input-container form-group">
                        <textarea className="input form-control textareaform" onChange={onInputChangeHandler} onBlur={onTouchHandler} name='message' value={userData.message} placeholder=" " maxLength={250}></textarea>
                        <div className="placeholder">{t("GETINTOUCH.TEXT8")}</div>
                        <span className="text-danger">
                            {formError['message'] &&
                                (formError['message'] === "req"
                                    ? ''
                                    : 'Enter a valid Message')}
                        </span>
                    </div>
                    <div className="recaptcha_css">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={CAPTCHA_SECRET_KEY}
                            onChange={onChange}
                            size='compact'
                        />
                    </div>
               
                    <button type="submit" disabled={formSubmitButtonDisable  } className="ctatwobutton" onClick={() => onSendMessageClick()}>{t("GETINTOUCH.TEXT9")}</button>

               
            </div>

                </div>
            </div>
            
        </div>
    </section>

}