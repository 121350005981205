import React, { useLayoutEffect } from 'react'
import { useEffect } from 'react';
import { Footer } from '../../common/footer/footer';
import productbanner from "../../../assets/images/productbanner.svg";
import rimib1 from "../../../assets/offersimage/RealEstate.svg";
import rimib2 from "../../../assets/offersimage/Investment.svg";
import rimib3 from "../../../assets/offersimage/Mortgage.svg";
import rimib4 from "../../../assets/offersimage/Insurance.svg";
import rimib5 from "../../../assets/offersimage/Banking.svg";
import ascending from "../../../assets/productimage/ascending-bars.svg";
import descending from "../../../assets/productimage/descending-bars.svg";
import selectall from "../../../assets/offersimage/Select_all.svg";
import productbannermobile from "../../../assets/images/Top_Product_Mobile.jpg";
import 'react-awesome-slider/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../products/products.css"
import "../products/productresponsive.css"
import { uploadURL, NEW_URL } from '../../../constants/applicationConstants';
import dropbox from "../../../assets/images/dropbox.svg";
import LazyLoad from 'react-lazyload';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const ProductJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    onSubmitButtonClick,
    formData,
    onInputChange,
    formError,
    investmentProductData,
    mortgageProductData,
    insuranceProductData,
    bankingProductData,
    realStateProductData,
    topProducts
  } = props;

  const { hash } = useLocation()

  useEffect(() => {
    AOS.init();

    if (hash) {
      document.getElementById(hash.replace('#', '')) && document.getElementById(hash.replace('#', '')).scrollIntoView()
    } else {
      window.scrollTo(0, 0);
    }

  }, [realStateProductData, investmentProductData, mortgageProductData, insuranceProductData, bankingProductData])

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '20px',

    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };
  var settingstwo = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 1,
          dots: true,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1,
          dots: true,
          arrows: false
        }
      }
    ]
  };
  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="newproductdesign">
        <section className="herosectionoffer">

          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-sm-7">
                <div className="offerherobannertext">

                  <h2>{t("PRODUCTS.TEXT1")}</h2>
                  <h5>{t("PRODUCTS.TEXT2")}</h5>
                </div>
              </div>
              <div className="col-lg-5 col-sm-5">
                <div className="offerbannerslider productbannerslider">
                  <Slider {...settingstwo}>
                    {
                      topProducts?.map(product => {

                        return (
                          <Link
                            to={{
                              pathname: "/detailedProduct",
                              state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'realestateProduct', pagePath: '/products' }
                            }}


                            onClick={() => window.scrollTo(0, 0)}>
                            <div className="sliderContent">
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${product.imageUrl}`}
                                alt=" "
                              />
                              {/* <h5>{product.productName}</h5> */}
                            </div>                            </Link>

                        )

                      })
                    }
                  </Slider>
                </div>
              </div>
            </div>

          </div>
        </section>
        <section className="offersearch">
          <div className="container">
            <div class="customsearchblock">
              <input type="search" placeholder="" />
              <button class="headersearch">{t("PRODUCTS.TEXT3")}</button>
            </div>
            <h2>{t("PRODUCTS.TEXT4")}</h2>
            <h4>{t("PRODUCTS.TEXT5")}</h4>
          </div>
        </section>

        {/* <section className="newserachfield">
        <div className="container">
          <div className="myexpertformarea searcharea martop40">
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="category"
                onChange={onInputChange}
              // onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {categoryData?.map((item) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Category</div>
            </div>
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="subCategory"
                value={formData.subCategory}

                onChange={onInputChange}
              // onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {subCategoryData?.map((item, i) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.subCategory}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Sub Category</div>
            </div>
            <div className="input-container form-group">
              <button type="submit" className="ctaonebutton" onClick={() => onSubmitButtonClick()}>Search Products</button>
            </div>
          </div>
        </div>
      </section> */}

        {/* <section className="herosection">
        <div className="container">
          <h2 className="text-center">{t("TOPPRODUCTS.PRODUCTTITLE")}</h2>
          <h5 className="text-center">{t("TOPPRODUCTS.PRODUCTSUBTITLE")}</h5>
          <h5 className="text-center">{t("TOPPRODUCTS.PRODUCTSUBTITLE1")}</h5>
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="heroleftpanel" data-aostest="fade-right">

                <h6 className="mt-5">{t("TOPPRODUCTS.PRODUCTSUBSUBTITLE")}<br />
                {t("TOPPRODUCTS.PRODUCTSUBSUBTITLE1")}<br />
                {t("TOPPRODUCTS.PRODUCTSUBSUBTITLE2")}</h6>
                <div className="heroleftbutton d-flex mt-4">
                  <Link to={'/AskTheExpert'} className="ctaonebutton"> Ask the Expert</Link>

                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="herorightpanel" data-aostest="fade-left">
                <img src={Consultingrafiki} alt={' '} />
              </div>
            </div>
          </div>
        </div>
      </section> */}
        <section className="mainofferdetails">
          <div className="container">
            <h2>{t("PRODUCTS.TEXT6")}</h2>
            <h5>{t("PRODUCTS.TEXT7")}</h5>
            <h5>{t("PRODUCTS.TEXT8")}<b>{t("PRODUCTS.TEXT9")}</b></h5>
            <div className="filtersectionglobal">
              <div className="selectallcheckbox">
                <div class="form-checkselect">

                  <input class="form-check-inputselect" type="checkbox" value="" id="Checkall" />
                  <span className="maincheckboxicon"><img src={selectall} /></span><label class="form-check-label mainchecklabel" for="Checkme4">{t("PRODUCTS.TEXT10")}</label>
                </div>
              </div>
              <div className="selectallinnercheckbox">
                <ul>
                  <li className="selectinner Real Estate" disabled>
                    <div class="dropdown">
                      <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                        <input class="form-check-input" type="checkbox" value="" id="Checkmerealestate" />
                        <span className="maincheckboxicon"><img src={rimib1} /></span><label class="form-check-label mainchecklabel" for="Checkme4">{t("PRODUCTS.TEXT11")}</label>

                      </div>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme1" />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span> <label class="form-check-label" for="Checkme1">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme2" checked />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                              <label class="form-check-label" for="Checkme2">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme3" />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                              <label class="form-check-label" for="Checkme3">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>


                      </ul>
                    </div>


                  </li>
                  <li className="selectinner Investment">
                    <div class="dropdown">
                      <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                        <input class="form-check-input" type="checkbox" value="" id="Checkmeinvestment" />
                        <span className="maincheckboxicon"><img src={rimib2} /></span><label class="form-check-label mainchecklabel" for="Checkme4">{t("PRODUCTS.TEXT13")}</label>

                      </div>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme1" />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span> <label class="form-check-label" for="Checkme1">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme2" checked />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                              <label class="form-check-label" for="Checkme2">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme3" />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                              <label class="form-check-label" for="Checkme3">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>


                      </ul>
                    </div>


                  </li>
                  <li className="selectinner Mortgage">
                    <div class="dropdown">
                      <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                        <input class="form-check-input" type="checkbox" value="" id="Checkmemortgage" />
                        <span className="maincheckboxicon"><img src={rimib3} /></span><label class="form-check-label mainchecklabel" for="Checkme4">{t("PRODUCTS.TEXT14")}</label>

                      </div>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme1" />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span> <label class="form-check-label" for="Checkme1">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme2" checked />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                              <label class="form-check-label" for="Checkme2">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme3" />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                              <label class="form-check-label" for="Checkme3">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>


                      </ul>
                    </div>


                  </li>
                  <li className="selectinner Insurance">
                    <div class="dropdown">
                      <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                        <input class="form-check-input" type="checkbox" value="" id="Checkmeinsurance" />
                        <span className="maincheckboxicon"><img src={rimib4} /></span><label class="form-check-label mainchecklabel" for="Checkme4">{t("PRODUCTS.TEXT15")}</label>

                      </div>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme1" />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span> <label class="form-check-label" for="Checkme1">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme2" checked />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                              <label class="form-check-label" for="Checkme2">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme3" />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                              <label class="form-check-label" for="Checkme3">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>


                      </ul>
                    </div>


                  </li>
                  <li className="selectinner Banking">
                    <div class="dropdown">
                      <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                        <input class="form-check-input" type="checkbox" value="" id="Checkmebanking" />
                        <span className="maincheckboxicon"><img src={rimib5} /></span><label class="form-check-label mainchecklabel" for="Checkme4">{t("PRODUCTS.TEXT16")}</label>

                      </div>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme1" />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span> <label class="form-check-label" for="Checkme1">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme2" checked />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                              <label class="form-check-label" for="Checkme2">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="Checkme3" />
                              <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                              <label class="form-check-label" for="Checkme3">{t("PRODUCTS.TEXT12")}</label>
                            </div>
                          </a>
                        </li>


                      </ul>
                    </div>


                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="sortingdiv">
          <div className="container">
            <span className="shortby">{t("PRODUCTS.TEXT17")}</span>
            <ul>
              <li className="active"><a href=""><img src={ascending} className="descending"/> {t("PRODUCTS.TEXT18")}</a></li>
              <li><a href=""><img src={descending} className="descending"/> {t("PRODUCTS.TEXT19")}</a></li>
            </ul>
          </div>
        </section>
        <section className="entireofferfiltercustomised">
          <div className="container">
            {realStateProductData?.length > 2 &&
              <section className="topexpertproduct">




                <div className="productslider mt-4">
                  <div className="row">

                    {
                      realStateProductData?.length > 0 ? realStateProductData?.map(product => {
                        return (
                          <div className={`col-lg-4 col-md-4 col-sm-4 sliouter ${product?.parentInstitutionCategory}`}>
                            <div className={`p_sliderouter ${product?.parentInstitutionCategory}`}>
                              <div className="p_slider">
                              <div className="parentcatname">{product?.parentInstitutionCategory}</div>
                                <div className="sldeimage">
                                  <LazyLoad>
                                    <img src={`${NEW_URL}/${product.imageUrl}`} onclick="zoomImage(event)" alt={' '} />
                                  </LazyLoad>
                                </div>
                                <h5>{product.productName}</h5>
                                <div className="slbtn">
                                  <div className="d-flex">
                                    
                                    <Link
                                      to={{
                                        pathname: "/detailedProduct",
                                        state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'realestateProduct', pagePath: '/products' }
                                      }}
                                      className="ctaonebutton ctanewtwobutton"
                                      onClick={() => window.scrollTo(0, 0)}>
                                      {t("PRODUCTS.TEXT20")}
                            </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }) :
                        <div className="dropdefault text-center">
                          <div className="dropdefaultimage">
                            <img src={dropbox} alt={' '} />
                          </div>
                          <div className="dropdefaulttext">
                            <h5>{t("PRODUCTS.TEXT21")} </h5>
                          </div>
                        </div>
                    }

                  </div>
                </div>

              </section>
            }
            {investmentProductData?.length > 2 &&
              <section className="topexpertproduct">
               


                  <div className="productslider mt-4">
 <div className="row">

                    {
                      investmentProductData?.length > 0 ? investmentProductData?.map(product => {
                        return (
                          <div className={`col-lg-4 col-md-4 col-sm-4 sliouter ${product?.parentInstitutionCategory}`}>
                            <div className={`p_sliderouter ${product?.parentInstitutionCategory} `}>
                              <div className="p_slider">
                              <div className="parentcatname">{product?.parentInstitutionCategory}</div>
                                <div className="sldeimage">
                                  <LazyLoad>
                                    <img src={`${NEW_URL}/${product.imageUrl}`} onclick="zoomImage(event)" alt={' '} />
                                  </LazyLoad>
                                </div>
                                <h5>{product.productName}</h5>
                                <div className="slbtn">
                                  <div className="d-flex">
                                   
                                    <Link
                                      to={{
                                        pathname: "/detailedProduct",
                                        state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'investmentProduct', pagePath: '/products' }
                                      }}
                                      className="ctaonebutton ctanewtwobutton"
                                      onClick={() => window.scrollTo(0, 0)}>
                                      {t("PRODUCTS.TEXT20")}
                            </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }) :
                        <div className="dropdefault text-center">
                          <div className="dropdefaultimage">
                            <img src={dropbox} alt={' '} />
                          </div>
                          <div className="dropdefaulttext">
                            <h5>{t("PRODUCTS.TEXT21")} </h5>
                          </div>
                        </div>
                    }

                  </div>
                </div>

              </section>
            }
            {mortgageProductData?.length > 2 &&
              <section className="topexpertproduct">

              
                  <div className="productslider mt-4">
  <div className="row">
                    {
                      mortgageProductData?.length > 0 ? mortgageProductData?.map(product => {
                        return (
                          <div className={`col-lg-4 col-md-4 col-sm-4 sliouter ${product?.parentInstitutionCategory}`}>
                            <div className={`p_sliderouter ${product?.parentInstitutionCategory} `}>
                              <div className="p_slider">
                              <div className="parentcatname">{product?.parentInstitutionCategory}</div>
                                <div className="sldeimage">
                                  <LazyLoad>
                                    <img src={`${NEW_URL}/${product.imageUrl}`} onclick="zoomImage(event)" alt={' '} />
                                  </LazyLoad>
                                </div>
                                <h5>{product.productName}</h5>
                                <div className="slbtn">
                                  <div className="d-flex">
                                    
                                    <Link
                                      to={{
                                        pathname: "/detailedProduct",
                                        state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'mortgageProduct', pagePath: '/products' }
                                      }}
                                      className="ctaonebutton ctanewtwobutton"
                                      onClick={() => window.scrollTo(0, 0)}>
                                      {t("PRODUCTS.TEXT20")}
                            </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }) :
                        <div className="dropdefault text-center">
                          <div className="dropdefaultimage">
                            <img src={dropbox} alt={' '} />
                          </div>
                          <div className="dropdefaulttext">
                            <h5>{t("PRODUCTS.TEXT21")} </h5>
                          </div>
                        </div>
                    }


                  </div>
                </div>
              </section>}
            {insuranceProductData?.length > 2 &&
              <section className="topexpertproduct">

                

                  <div className="productslider mt-4">
<div className="row">

                    {
                      insuranceProductData?.length > 0 ? insuranceProductData?.map(product => {
                        return (
                          <div className={`col-lg-4 col-md-4 col-sm-4 sliouter ${product?.parentInstitutionCategory}`}>
                            <div className={`p_sliderouter ${product?.parentInstitutionCategory} `}>
                              <div className="p_slider">
                              <div className="parentcatname">{product?.parentInstitutionCategory}</div>
                               
                                <div className="sldeimage">
                                  <LazyLoad>
                                    <img src={`${NEW_URL}/${product.imageUrl}`} onclick="zoomImage(event)" alt={' '} />
                                  </LazyLoad>
                                </div>
                                 <h5>{product.productName}</h5>
                                <div className="slbtn">
                                  <div className="d-flex">
                                   
                                    <Link
                                      to={{
                                        pathname: "/detailedProduct",
                                        state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'insuranceProduct', pagePath: '/products' }
                                      }}
                                      className="ctaonebutton ctanewtwobutton"
                                      onClick={() => window.scrollTo(0, 0)}>
                                      {t("PRODUCTS.TEXT20")}
                            </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }) :
                        <div className="dropdefault text-center">
                          <div className="dropdefaultimage">
                            <img src={dropbox} alt={' '} />
                          </div>
                          <div className="dropdefaulttext">
                            <h5>{t("PRODUCTS.TEXT21")} </h5>
                          </div>
                        </div>
                    }


                  </div>
                </div>
              </section>}
            {bankingProductData?.length > 2 &&
              <section className="topexpertproduct">
                


                  <div className="productslider mt-4">
<div className="row">
                    {
                      bankingProductData?.length > 0 ? bankingProductData?.map(product => {
                        return (
                          <div className={`col-lg-4 col-md-4 col-sm-4 sliouter ${product?.parentInstitutionCategory}`}>
                            <div className={`p_sliderouter ${product?.parentInstitutionCategory} `}>
                              <div className="p_slider">
                              <div className="parentcatname">{product?.parentInstitutionCategory}</div>
                                <div className="sldeimage">
                                  <LazyLoad>
                                    <img src={`${NEW_URL}/${product.imageUrl}`} onclick="zoomImage(event)" alt={' '} />
                                  </LazyLoad>
                                </div>  <h5>{product.productName}</h5>
                                <div className="slbtn">
                                  <div className="d-flex">

                                  
                                    <Link
                                      to={{
                                        pathname: "/detailedProduct",
                                        state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'bankingProduct', pagePath: '/products' }
                                      }}
                                      className="ctaonebutton ctanewtwobutton"
                                      onClick={() => window.scrollTo(0, 0)}>
                                      {t("PRODUCTS.TEXT20")}
                            </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }) :
                        <div className="dropdefault text-center">
                          <div className="dropdefaultimage">
                            <img src={dropbox} alt={' '} />
                          </div>
                          <div className="dropdefaulttext">
                            <h5>{t("PRODUCTS.TEXT21")}</h5>
                          </div>
                        </div>
                    }


                  </div>
                </div>
              </section>
            }
          </div>
        </section>
      </section>
      <Footer />
    </>

  );
};

export default ProductJsx;