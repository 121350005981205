import React, { useState } from 'react'
import { Footer } from '../../../common/footer/footer';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import dropbox from "../../../../assets/images/dropbox.svg";
import rimib1 from "../../../../assets/homeimages/R.svg";
import rimib11 from "../../../../assets/homeimages/realestateicon.png";
import rimib2 from "../../../../assets/homeimages/I-1.svg";
import rimib22 from "../../../../assets/homeimages/investicon.png";
import rimib3 from "../../../../assets/homeimages/M.svg";
import rimib33 from "../../../../assets/homeimages/mortgageicon.png";
import rimib4 from "../../../../assets/homeimages/I.svg";
import rimib44 from "../../../../assets/homeimages/insurabceicon.png";
import rimib5 from "../../../../assets/homeimages/B.svg";
import rimib55 from "../../../../assets/homeimages/bankingicon.png";
import Sidebar from "../sidebar/sidebar.js";
import realeicon from "../../../../assets/images/realestaticonblog.png"

const CustomerBlogJsx = (props) => {
    const {
        blogData,
        onBlogTypeClick
    } = props;

    const [openModal, setOpenModal] = useState(false);
    const [blog, setBlog] = useState({});

    const onSelectClick = (title) => {
        onBlogTypeClick(title);
    }

    return (
        <>
            <section class="entiredashboard blogs maincustomerdash blogs-main">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3">


                            <div className="dashboardleftpanel">

                                <div className="dashboardlinkpop">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-9 blogsinner main-blogs">
                            <div>
                                <Tabs
                                    defaultActiveKey="real-estate" id="justify-tab-example" className="myexpertnav myaccountnav qnanav martop40 customermain round-box" onSelect={title => onSelectClick(title)}
                                >
                                    
                                    <Tab eventKey="real-estate" title="Real Estate" >
                                        <div className="row acc-home main-blog-section">
                                            {
                                                blogData?.body?.length > 0 ?
                                                    blogData?.body?.map(blog => {
                                                        return <div className="col-lg-4 col-md-6 col-sm-6 p-0 blogblogmain">
                                                            <a onClick={() => { setBlog(blog); setOpenModal(true) }} className="realestatbuttonblogs">
                                                                <div className="bloginner mainblog-rimib">
                                                                    <div className="bloginnerimage "><img src={blog?.image} alt={''} /></div>
                                                                    <div className="blog-design">
                                                                    <div className="bloginnertext blog-text ">{blog?.title}</div>
                                                                    <div className="bloginnerdate blog-view mt-3">{blog?.views} views  .  {blog?.date_publish}</div>
                                                                    <div className="bloginnertag blog-button mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                                                                    </div>
                                                                </div>

                                                            </a>
                                                        </div>
                                                    }) :

                                                    <div className="dropdefault text-center">
                                                        <div className="dropdefaultimage">
                                                            <img src={dropbox} alt={''} />
                                                        </div>
                                                        <div className="dropdefaulttext">
                                                            <h5>You don't have any Blogs yet. </h5>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Investment" title="Investment">
                                        <div className="row acc-home main-blog-section">
                                            {
                                                blogData?.body?.length > 0 ?
                                                    blogData?.body?.map(blog => {
                                                        return <div className="col-lg-4 col-sm-4 p-0 blogblogmain">
                                                            <a onClick={() => { setBlog(blog); setOpenModal(true) }} >
                                                                <div className="bloginner mainblog-rimib ">
                                                                    <div className="bloginnerimage"><img src={blog?.image} alt={''} /></div>
                                                                   <div className="blog-design-investment">

                                                                    <div className="bloginnertext bloginnertext-investment blog-text">{blog?.title}</div>
                                                                    <div className="bloginnerdate blog-view-investment mt-3">{blog?.views} views  .  {blog?.date_publish}</div>
                                                                    <div className="bloginnertag blog-button-investment mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                                                                   </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    }) :

                                                    <div className="dropdefault text-center">
                                                        <div className="dropdefaultimage">
                                                            <img src={dropbox} alt={''} />
                                                        </div>
                                                        <div className="dropdefaulttext">
                                                            <h5>You don't have any Blogs yet. </h5>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Mortgage" title="Mortgage">
                                        <div className="row acc-home main-blog-section">
                                            {
                                                blogData?.body?.length > 0 ?
                                                    blogData?.body?.map(blog => {
                                                        return <div className="col-lg-4 col-sm-4 p-0 blogblogmain">
                                                            <a onClick={() => { setBlog(blog); setOpenModal(true) }} >
                                                                <div className="bloginner mainblog-rimib ">
                                                                    <div className="bloginnerimage"><img src={blog?.image} alt={''} /></div>
                                                                   <div className="blog-design-Mortgage ">
                                                                    <div className="bloginnertext bloginnertext-Mortgage blog-text ">{blog?.title}</div>
                                                                    <div className="bloginnerdate blog-view-Mortgage  mt-3">{blog?.views} views  .  {blog?.date_publish}</div>
                                                                    <div className="bloginnertag blog-button-Mortgage mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                                                                   </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    }) :

                                                    <div className="dropdefault text-center">
                                                        <div className="dropdefaultimage">
                                                            <img src={dropbox} alt={''} />
                                                        </div>
                                                        <div className="dropdefaulttext">
                                                            <h5>You don't have any Blogs yet. </h5>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Insurance" title="Insurance">
                                        <div className="row acc-home main-blog-section">
                                            {
                                                blogData?.body?.length > 0 ?
                                                    blogData?.body?.map(blog => {
                                                        return <div className="col-lg-4 col-sm-4 p-0 blogblogmain">
                                                            <a onClick={() => { setBlog(blog); setOpenModal(true) }} >
                                                                <div className="bloginner mainblog-rimib ">
                                                                    <div className="bloginnerimage"><img src={blog?.image} alt={''} /></div>
                                                                    <div className="blog-design-Insurance">

                                                                    <div className="bloginnertext bloginnertext-Insurance blog-text ">{blog?.title}</div>
                                                                    <div className="bloginnerdate  blog-view-Insurance mt-3">{blog?.views} views  .  {blog?.date_publish}</div>
                                                                    <div className="bloginnertag blog-button-Insurance  mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    }) :

                                                    <div className="dropdefault text-center">
                                                        <div className="dropdefaultimage">
                                                            <img src={dropbox} alt={''} />
                                                        </div>
                                                        <div className="dropdefaulttext">
                                                            <h5>You don't have any Blogs yet. </h5>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Banking" title="Banking">
                                        <div className="row acc-home main-blog-section">
                                            {
                                                blogData?.body?.length > 0 ?
                                                    blogData?.body?.map(blog => {
                                                        return <div className="col-lg-4 col-sm-4 p-0 blogblogmain">
                                                            <a onClick={() => { setBlog(blog); setOpenModal(true) }} >
                                                                <div className="bloginner  mainblog-rimib ">
                                                                    <div className="bloginnerimage"><img src={blog?.image} alt={''} /></div>
                                                                    <div className="blog-design-Banking">

                                                                    <div className="bloginnertext bloginnertext-Banking blog-text ">{blog?.title}</div>
                                                                    <div className="bloginnerdate blog-view-Banking mt-3">{blog?.views} views  .  {blog?.date_publish}</div>
                                                                    <div className="bloginnertag blog-button-Banking  mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    }) :

                                                    <div className="dropdefault text-center">
                                                        <div className="dropdefaultimage">
                                                            <img src={dropbox} alt={''} />
                                                        </div>
                                                        <div className="dropdefaulttext">
                                                            <h5>You don't have any Blogs yet. </h5>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    openModal &&
                    <div className="modalspecial blog-modal">
                        <div>
                            <div className="mybtn">
                                <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
                            </div>
                            <div className="modal-header text-center">
                                <h3>{blog?.title}</h3>
                            </div>
                            <div className="modal-body text-center">
                                <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                                </div>
                                <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                            </div>
                        </div>
                    </div>

                }


            </section>
            <Footer />

        </>

    );
};

export default CustomerBlogJsx;