import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { Loader } from '../../../common/loader/loader.jsx';
import ConfirmAskTheExpertJsx from './confirmAskTheExpert.jsx'

export default class ConfirmAskTheExpert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: null,
            subCategory: null,
            subCategoryData: null,
            question: null,
            formData: null,
            isLoading: false,
            categoryId: null,
            subCategoryId: null
        }
    }

    componentDidMount = () => {
        const data = JSON.parse(sessionStorage.getItem('askTheExpert'));
        const { categoryName, subCategoryName, questionAsked, formData, categoryId, subCategoryId } = data;
        this.setState({ category: categoryName, subCategory: subCategoryName, question: questionAsked, formData: formData, subCategoryId: subCategoryId, categoryId: categoryId });
    }

    onEditButtonClick = () => {
        // this.props.history.push('/askTheExpert');
    }

    onConfirmAndSubmitButtonClick = () => {
        this.setState({ isLoading: true });
        const payload = {
            "expertCategoryId": this.state.categoryId,
            "expertSubCategoryId": this.state.subCategoryId,
            "phoneNUmber": this.state.formData.phoneNumber,
            "fullName": this.state.formData.name,
            "postalCode": this.state.formData.postalCode,
            "email": this.state.formData.email,
            "question": this.state.question
        }
        axios.post(`${baseURL}/askExpertQuestionUserResponse`, payload).then(res => {
            this.props.openFinal()
            this.props.resetState()
            sessionStorage.removeItem("askTheExpert")
            // window.location.reload()
            // this.props.history.push('/confirmAndSubmitAskTheExpert');
            this.setState({ isLoading: false })
        }).catch(err => {
            this.setState({ isLoading: false })
            toast.error(err?.response.data.error.message);
        })
    }

    render() {
        return (
            <div>
                <ConfirmAskTheExpertJsx
                    category={this.state.category}
                    subCategory={this.state.subCategory}
                    subCategoryData={this.state.subCategoryData}
                    question={this.state.question}
                    onEditButtonClick={this.onEditButtonClick}
                    onConfirmAndSubmitButtonClick={this.onConfirmAndSubmitButtonClick}
                    closeModalEdit={this.props.closeModalEdit}
                />
                {this.state.isLoading && <Loader />}
                <ToastContainer />
            </div>
        )
    }
}