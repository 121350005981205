import React, { useEffect, useState } from 'react'
import dashboard from "../../../../assets/images/dashboard.svg";
import myexpert from "../../../../assets/images/myexpert.svg";
import myworkspace from "../../../../assets/images/myworkspace.svg";
import myblog from "../../../../assets/images/myblog.svg";
import myaccount from "../../../../assets/images/myaccount.svg";
import mypayment from "../../../../assets/images/mypayment.svg";
import logoutIcon from "../../../../assets/images/logout.svg";
import User_Manual from "../../../../assets/images/User_Manual.svg"
import { Link } from "react-router-dom";
import { uploadURL, NEW_URL, baseURL } from '../../../../constants/applicationConstants'; import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import axios from 'axios'

const SideBarsJsx = (props) => {
  const history = useHistory();
  const [state, setState] = useState()

  const {
    response
  } = props;

  useEffect(() => {
    callDetails()
  }, []);

  const callDetails = () => {
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let instituteId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    const instUserId = sessionStorage.getItem('instUserId') ?? '';

    let url = `${baseURL}/institute/${instituteId}/details`
    let secondary_url = `${baseURL}/detailsInstituteUser/${instUserId}`

    const adminLoginData = sessionStorage.getItem('adminLoginData');
 

    axios.get(instUserId? secondary_url : url, {
      headers: { 'x-access-token': token }
    }).then(res => {

      setState(res.data.data)
      if (!adminLoginData && !res.data?.data?.isPermanentPasswordSet) {
        history.push("/saveNewPassword");
        // window.location.reload()
      }

    }).catch(err => {
    })
  }
  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      buttons: [
        'Continue',
        'Logout'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("legalName");
        sessionStorage.removeItem("isLoggedIn");

        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }
  // {t("PRINT.TITLE")}
  return (
    <>
      <div className="dashboardleftpanel martop40">
        <div className="userdetails">
          <div className="dashboardlogo"><img src={`${NEW_URL}/${response?.imageUrl}`} alt={''}></img></div>
          <div className="responsename">{response?.name} <Link to={`/institute/${response?.formattedLegalName}`} target="_blank" rel="noreferrer" className="viewprofilebtn">View Profile</Link></div>
        </div>
        <div className="dashboardlink martop40">
          <ul>
            <li className={`${window.location.href?.toLowerCase().includes('dashboard') ? 'active' : ""}`}><Link to={'/dashBoard'}><img src={dashboard} alt={''} />Dashboard</Link></li>
            <li className={`${window.location.href?.toLowerCase().includes('myexpert') ? 'active' : ""}`}><Link to={'/myExpert'}><img src={myexpert} alt={''} />My Experts</Link></li>
            <li className={`${window.location.href?.toLowerCase().includes('myworkspace') ? 'active' : ""}`}><Link to={'/myWorkspace'}><img src={myworkspace} alt={''} />My Workspace</Link></li>
            <li className={`${window.location.href?.toLowerCase().includes('myblog') ? 'active' : ""}`}><Link to={'/myBlog'}><img src={myblog} alt={''} />My Blogs</Link></li>
            <li className={`${window.location.href?.toLowerCase().includes('myaccounts') ? 'active' : ""}`}><Link to={'/myAccounts'}><img src={myaccount} alt={''} />My Account</Link></li>
            <li className={`${window.location.href?.toLowerCase().includes('mypayment') ? 'active' : ""}`}><Link to={'/myPayment'}><img src={mypayment} alt={''} />My Payment</Link></li>
            <li><a href={'/manual/RIMIB-User-Manual.pdf'} target="_blank"><img src={User_Manual} alt={''} />User Manual</a></li>
            <li onClick={logout}><img src={logoutIcon} alt={''} /><span>Logout</span></li>
          </ul>
        </div>
      </div>
    </>

  );
};

export default SideBarsJsx;