import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { baseURL } from '../../../../constants/applicationConstants.js';
import { emailRegex, nameRegex, postalCodeRegex } from '../../../../shared/helper.js';
import { Loader } from '../../../common/loader/loader.jsx';
import CustomerAskTheExpertJsx from './roleManage.jsx'

export default class AskTheExpert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            isLoading: false,
            checkedItems: [],
        }
    }

    // Handle change for checkboxes
  handleChange = (data) => {
    this.setState({
      checkedItems: [
        ...this.state.checkedItems,
        data,
      ],
    })    
  };

  // Get checked IDs
  getCheckedIds = () => {
    
    console.log("getCheckedIds",this.state.checkedItems)
  };

    componentDidMount = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/modules`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        })

    }
   

   

    render() {
        return (
            <div>
                <CustomerAskTheExpertJsx
                    categoryData={this.state.categoryData}
                    getCheckedIds={this.getCheckedIds}
                    handleChange={this.handleChange}
                />
                {this.state.isLoading && <Loader />}
                <ToastContainer />            </div>
        )
    }
}
