import React from 'react'
import { useEffect, useState } from 'react';
import call from "../../../../assets/images/call.svg";
import { Pagination } from '../../../common/pagination/pagination';

import map from "../../../../assets/images/map.svg";
import envelop from "../../../../assets/images/envelop.svg";
import InputMask from 'react-input-mask';
import { Footer } from '../../../common/footer/footer';
import { uploadURL, NEW_URL } from '../../../../constants/applicationConstants.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'
import { baseURL } from '../../../../constants/applicationConstants.js';
import moment from 'moment';
import Sidebar from "../sidebar/sidebar.js";

import axios from 'axios';

export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    handleChange,
    getCheckedIds

  } = props;








  // {t("PRINT.TITLE")}
  return (
    <>
      <section class="entiredashboard admindashboard">

        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">

              <div className="dashboardrightpanel martop40">
                <h2>Role Management</h2>



                <div className="expertsection martop30 mb-5">
                  {
                    categoryData?.length > 0 ?
<>
                      <div className="searchdetails">


                        {
                          categoryData?.map(data => {
                            return <div className="searchdetails">
                              <h3>{data?.moduleName}</h3>

                              {
                                data?.modulePermissions?.map(role => {
                                  return <div>
                                    <input type="checkbox" id={role.name} name={role.name} value={role.modulePermissionId}  
                                            onChange={(e) => handleChange({ "permissionId": role.modulePermissionId, "moduleId": data.moduleId })}
                                                  />
            

                                      <label for={role.name}> {role.name}</label>
                                  </div>
                                  

                                })
                              }


                            </div>
                                })
                              }


                            </div>
                             <button onClick={() =>getCheckedIds()}>
                             Show Checked IDs
                           </button>
                            </>
                            
                      :
                      null
                  }



                </div>
              </div>
              </div>
            </div>

          </div>



      </section><Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;
