import React from 'react'
import Homelogo from "../../../../assets/images/Homelogo.png";

import { Link } from "react-router-dom";
import { NEW_URL } from '../../../../constants/applicationConstants';
import swal from "sweetalert";

import user from "../../../../assets/images/user.png";

import blog from "../../../../assets/images/blog.png";
import interests from "../../../../assets/images/interests.png";
import account from "../../../../assets/images/account.png";
import logoutlogo from "../../../../assets/images/logout.png";
import { useHistory } from "react-router-dom";

const SideBarsJsx = (props) => {
  const history = useHistory();

  const {
    response
  } = props;

  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      buttons: [
        'Continue',
        'Logout'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("legalName");
        sessionStorage.removeItem("isLoggedIn");

        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }
  // {t("PRINT.TITLE")}
  return (
    <>
     <div className="dashboard-container maincustomerdashboard">
  <div className="dashboardleftpanel">
    {/* <div className="image-column">
      <div className="userdetails">
        <div className={`sliderimg ${window.location.href?.toLowerCase().includes('customerdashboard') ? 'activeimg' : ''}`}>
          <img className="leftborder" src={Homelogo} alt={''} />
        </div>
        <div className={`sliderimg ${window.location.href?.toLowerCase().includes('customerasktheexpert') ? 'activeimg' : ''}`}>
          <img className="leftborder" src={user} alt={''} />
        </div>
        <div className={`sliderimg ${window.location.href?.toLowerCase().includes('customerblogs') ? 'activeimg' : ''}`}>
          <img className="leftborder" src={blog} alt={''} />
        </div>
        <div className={`sliderimg ${window.location.href?.toLowerCase().includes('customerinterest') ? 'activeimg' : ''}`}>
          <img className="leftborder" src={interests} alt={''} />
        </div>
        <div className={`sliderimg ${window.location.href?.toLowerCase().includes('customeraccounts') ? 'activeimg' : ''}`}>
          <img className="leftborder" src={account} alt={''} />
        </div>
        <div className="sliderimg">
          <img className="leftborder" src={logoutlogo} alt={''} />
        </div>
      </div>
    </div> */}

    <div className="text-column">
      <div className="responsename">
        <div className="dashboardlogo">
          <img
            src={!response?.imageUrl
              ? `https://ui-avatars.com/api/?name=${response?.firstName}+${response?.lastName}&rounded=true`
              : `${NEW_URL}/${response?.imageUrl}`}
            alt={''} 
          />
        </div>
        {`${response?.firstName} ${response?.lastName}`}
      </div>

      <div className="dashboardlink dashboardtext mydash">
        <ul className="dashboardinner">
          <li >
            <Link to={'/customerDashboard'}>
            <span className="menusideimage"><img className="leftborder" src={Homelogo} alt={''} /></span>  <p className={`${window.location.href?.toLowerCase().includes('customerdashboard') ? 'activetext' : ''}`}>Home</p> 
            </Link>
          </li>
          <li >
            <Link to={'/customerAskTheExpert'}>
                <span className="menusideimage"><img className="leftborder" src={user} alt={''} /></span><p className={`${window.location.href?.toLowerCase().includes('customerasktheexpert') ? 'activetext' : ''}`}>Ask The Experts</p>
            </Link>
          </li>
          <li >
            <Link to={'/customerBlogs'}>
               <span className="menusideimage"><img className="leftborder" src={blog} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('customerblogs') ? 'activetext' : ''}`}>Blogs</p>
            </Link>
          </li>
          <li >
            <Link to={'/customerInterest'}>
               <span className="menusideimage"><img className="leftborder" src={interests} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('customerinterest') ? 'activetext' : ''}`}>Interests</p>
            </Link>
          </li>
          <li >
            <Link to={'/customerAccounts'}>
               <span className="menusideimage"><img className="leftborder" src={account} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('customeraccounts') ? 'activetext' : ''}`}>Account Settings</p>
            </Link>
          </li>
          <li onClick={logout}>
            <a>
              <span className="menusideimage"><img className="leftborder" src={logoutlogo} alt={''} /></span><p>Logout</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>


    </>

  );
};

export default SideBarsJsx;