import React from 'react'
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Consultingrafiki from "../../../assets/images/Consulting-rafiki.svg";
import { Footer } from '../../common/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { uploadURL, NEW_URL } from '../../../constants/applicationConstants';
import dropbox from "../../../assets/images/dropbox.svg";
import cross from "../../../assets/images/cross.svg";
import bankingbanner from "../../../assets/images/Bankingbanner.svg";
import { useLocation } from 'react-router-dom';
import borderbottom from "../../../assets/bankingimges/borderbottom.png";
import iconaskbtn from "../../../assets/bankingimges/iconaskbtn.svg";
import "../banking/banking.css";
import "../banking/bankingresponsive.css";
import toprealE1 from "../../../assets/bankingimges/toprealE1.png";
import toprealE2 from "../../../assets/bankingimges/toprealE2.png";
import toprealE3 from "../../../assets/bankingimges/toprealE3.png";
import toprealE4 from "../../../assets/bankingimges/toprealE4.png";
import RealEtext from "../../../assets/bankingimges/RealEtext.png";
import toprealEttextline from "../../../assets/bankingimges/toprealEttextline.png";
import expertuiserborder from "../../../assets/bankingimges/expertuiserborder.png";


const BankingJsx = (props) => {
  const { t } = useTranslation();
  const {
    offerData,
    productData,
    adData,
    expertData,
    instituteData,
    blogData,
    userData,
    onTouchHandler,
    onInputChangeHandler,
    onOfferSearchButtonClick,
    onAdSearchButtonClick,
    onTypeOfProductSearchButtonClick,
    onExpertClick,
    onInstituteClick, subCategoryData
  } = props;
  const { hash } = useLocation()
  useEffect(() => {
    AOS.init();
    if (hash) {
      document.getElementById(hash.replace('#', '')).scrollIntoView()
    } else {
      //window.scrollTo(0, 0);
    }

  }, [])

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: false,
    centerPadding: '20px',
    //  initialSlide:2    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var expmorgage = {
    dots: false,
    adaptiveHeight: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '20px',
    //  initialSlide:2    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState(null);

  // {t("PRINT.TITLE")}
  const [visibleBlogs, setVisibleBlogs] = useState(4);
  const handleViewAll = () => {
    setVisibleBlogs(blogData?.body?.length);
  };

  return (
    <>
      {/* <section className="herosectionoffer categorysectiondis d-none d-sm-block">
    <img src={bankingbanner} alt=""/>
    
    <Link to={'/AskTheExpert'} className="ctaonebutton">Ask the Experts</Link>
    
    
    </section> */}
    <section className="bankingnewui">
      <section className=" herosectionoffer categorysectiondis bankingBanner banking">
        <div className="container makethecontainer">
          <div className="row">
            <div className="col-lg-12 col-md-6 col-12 p-0 realEinner">
              <div className="rimibmentfont borderbottom ">
                <h2>{t("BANKINGCHILD.TEXT1")}</h2>
                <img className="borderimg" src={borderbottom} alt="" />
                <p>{t("BANKINGCHILD.TEXT2")}</p>
                <h6 className="h6_style "><b className="realEbold"> {t("BANKINGCHILD.TEXT4")}</b>{t("BANKINGCHILD.TEXTTWO4")}<b className="realEbold"> {t("BANKINGCHILD.TEXTFOUR4")}</b> {t("BANKINGCHILD.TEXTFIVE4")}</h6>
                <p className="rimibmentsubtext">{t("BANKINGCHILD.TEXTSIX4")}</p>
                <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                  <div className="realEbuttonmain realEhero askbuttoncomm">
                    <p className="realEbutton">{t("BANKINGCHILD.TEXT22")}</p>
                    <img src={iconaskbtn} alt="" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="homepageproductoffer  institumainsection  bankingtwo" id="homerealestate">
        <div className="container">
          <div className="letest-inner resclass">
            <h2>{t("BANKINGCHILD.TEXT3")}</h2>
            <h6>{t("BANKINGCHILD.TEXTSIX4")}
              <br />{t("BANKINGCHILD.TEXTONE5")}
              <br />{t("BANKINGCHILD.TEXT6")}</h6>
          </div>
          <div className="institumain displaynone">
            <div className="latestbutton margintoptop">
              <button href="#" className="bankingviewallbuttoncomm" style={{ textDecoration: "none" }}>{t("BANKINGCHILD.TEXT23")}</button>
            </div>
          </div>

          <div className="row  offersss ">
            {offerData?.length > 0 ? (
              <Slider {...settings}>
                {offerData.map((offer) => {
                  return (
                    <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 mb-5">
                      <div className="offer-insurence-inner">
                        <div className="offer-image">
                          <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />
                        </div>
                        <div className="offer-name  disfontsmall">
                          {offer?.offerName}
                        </div>
                        <div className="posbuttontf d-flex foffer-of">
                          <a
                            href={offer?.externalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className=" text-center bankingapplynowbuttoncomm"
                          >
                            {t("BANKINGCHILD.TEXT25")}
                          </a>
                          <Link
                            to={{
                              pathname: "/detailedOffer",
                              state: { data: offer, frompage: "homerealestate", pagePath: "/realEstate" },
                            }}
                            className="bankingviewdetailsbuttoncomm text-center ctanewtwobutton"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {t("BANKINGCHILD.TEXT26")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">{t("BANKINGCHILD.TEXT27")}</h5>
                </div>
              </div>
            )}
            <div className="latestbutton ">
              
            <Link to={'/offers/4'} className="bankingviewallbuttoncomm" style={{ textDecoration: "none" }}>{t("BANKINGCHILD.TEXT23")}</Link>
            </div>
           
          </div>
        </div>
      </section>
      <section className="homepageproductoffer  bankingthree">
        <div className="container">
          <div className="TopRealEstateProducts-inner">
            <h2 className="investthreesubtext">{t("BANKINGCHILD.TEXT7")}</h2>
            <h6>{t("BANKINGCHILD.TEXT8")}
              <br />{t("BANKINGCHILD.TEXTONE8")}
              <br /><span>{t("BANKINGCHILD.TEXTTWO8")}</span></h6>
          </div>
          <div className="row  offersss">
            {productData?.length > 0 ? (
              <Slider {...settings}>
                {productData.map((data, offer) => {
                  return (
                    <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 mb-5">
                      <div className="offer-insurence-inner">
                        <div className="offer-image">
                          <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />

                          <img src={`${NEW_URL}/${data?.imageUrl}`} alt={''} />

                        </div>
                        <div className="offer-name mt-3 mb-3 disfontsmall">
                          {data?.productName}
                        </div>
                        <div className="posbuttontf d-flex">
                          <a
                            href={offer?.externalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bankingapplynowbuttoncomm text-center t"
                          >
                            {t("BANKINGCHILD.TEXT25")}
                          </a>
                          <Link
                            to={{
                              pathname: "/products",
                              state: { data: offer, frompage: "homerealestate", pagePath: "/realEstate" },
                            }}
                            className="bankingviewdetailsbuttoncomm text-center ctanewtwobutton"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {t("BANKINGCHILD.TEXT26")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">{t("BANKINGCHILD.TEXT27")}</h5>
                </div>
              </div>
            )}
            <div className="latestbutton ">
              
              <button href="#" className="bankingviewallbuttoncomm" style={{ textDecoration: "none" }}>{t("BANKINGCHILD.TEXT23")}</button>
            </div>
            
          </div>
        </div>
      </section>
      <section className="homepageproductoffer  bankingfour">
        <div className="container">
          <h2 className="text-center mb-3 letestadsheading"> {t("BANKINGCHILD.TEXT9")}</h2>
          <h6 className="h6_style text-center realeheading mb-0">{t("BANKINGCHILD.TEXT10")}</h6>
          <h6 className="h6_style text-center realeheading">{t("BANKINGCHILD.TEXTONE10")}</h6>
          <div className="row  offersss">
            {adData?.length > 0 ? (
              <Slider {...settings}>
                {adData.map((data, offer) => {
                  return (
                    <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 mb-5">
                      <div className="offer-insurence-inner">
                        <div className="offer-image">
                          <img src={`${NEW_URL}/${data?.imageUrl}`} alt={''} />
                        </div>
                        <div className="offer-name mt-3 mb-3 disfontsmall">
                          {data?.subInstitutionCategory?.name}
                        </div>
                        <div className="posbuttontf d-flex">
                          <a
                            href={offer?.externalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bankingviewdetailsbuttoncomm text-center "
                          >
                            {t("BANKINGCHILD.TEXT25")}
                          </a>
                          <Link
                            to={{
                              pathname: "/detailedOffer",
                              state: { data: offer, frompage: "homerealestate", pagePath: "/realEstate" },
                            }}
                            className="bankingviewdetailsbuttoncomm text-center ctanewtwobutton"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {t("BANKINGCHILD.TEXT26")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">{t("BANKINGCHILD.TEXT27")}</h5>
                </div>
              </div>
            )}
            <div className="latestbutton ">
      
              <button href="#" className="bankingviewallbuttoncomm" style={{ textDecoration: "none" }}>{t("BANKINGCHILD.TEXT23")}</button>
            </div>
          </div>
        </div>
      </section>
      <section className="morgaged insuranced  bankingfive">
        <div className="container">
          <div className="row toprealEflexflow" >
            <div className="col-lg-5 col-12 p-0 ">
              <div className="toprealEmain">
                <div className="row ">
                  {instituteData?.length > 0 ? (
                    instituteData.map((institute, index) => (
                      <div className="col-lg-6 col-sm-4 " key={index}>
                        <div
                          className="colinstititeinner"
                          onClick={() => onInstituteClick(institute)}
                        >
                          <div className="colinstititeinnerimage text-center">
                            <img
                              src={`${NEW_URL}/${institute?.imageUrl}`}
                              alt={institute?.name || "Institute Image"}
                            />
                          </div>
                          <div className="colinstititeinnername text-center institute-text">
                            {institute?.name}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="dropdefault text-center">
                      <div className="dropdefaultimage">
                        <img src={dropbox} alt="No records" />
                      </div>
                      <div className="dropdefaulttext">
                        <h5 className="opacity0">No Record Found !!</h5>
                      </div>
                    </div>
                  )}


                </div>

              </div>
              <div className="showallbtn text-center mt-3 shallbutton">
                <a
                  href="/searchInstitute"
                  className="toprealEbutton"
                  style={{ textDecoration: "none" }}
                >
                  Show All
  </a>
              </div>

            </div>
            <div className="col-lg-7 col-12 p-0">
              <div className="toprealErighttext">
                <h2>{t("BANKINGCHILD.TEXT11")}<span><img className="toprealEtext" src={RealEtext} alt="" />{t("BANKINGCHILD.TEXTONE11")}</span> <span className="canada">{t("BANKINGCHILD.TEXTTWO11")}</span></h2>
                <img className="toprealEborder" src={toprealEttextline} alt="" />
                <p><span className="topsubtext"> {t("BANKINGCHILD.TEXT12")}</span> {t("BANKINGCHILD.TEXTTWO12")}<span className="topsubtext"> {t("BANKINGCHILD.TEXTTHREE13")}</span> {t("BANKINGCHILD.TEXTFOUR13")}</p>
                <span className="topsubtextfive">{t("BANKINGCHILD.TEXT14")}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="homepageproductoffer bestdeals Experts bankingsix  ">
        <div className="container-fluid">
          <h2 className="text-center expertheading">{t("BANKINGCHILD.TEXT15")}</h2>
          <h5 className="text-center  h5_style expertsub1">{t("BANKINGCHILD.TEXT16")}<br />
          </h5>
          <p className="expertsub2"> {t("BANKINGCHILD.TEXT17")}</p>

          <div className=" p bestdealsbox bestexperts">
            {expertData?.length > 0 ?
              <Slider {...expmorgage}>
                {expertData?.map(data => {
                  return (
                    <div class="mt-5">
                      <div className="Expertsitmes" onClick={() => onExpertClick(data?.slugUrl)}>
                        <div className="Expertstop"><img src={`${NEW_URL}/${data?.imageUrl}`} alt={' '} />
                        </div>
                        <div className="Expertsbottom Expertstext text-center">
                          <div className="Expertstext"><h5>{`${data.firstName} ${data.lastName}`}</h5> <img src={expertuiserborder} alt="" /></div>
                          <p>{data?.description}</p>
                          <div className="Expertsbutton">
                            <div className="Expertsbuttoni">
                              <a href="" className="bankingmessagemebuttoncomm">{t("BANKINGCHILD.TEXT28")}</a>
                              <a href="" className="bankingmyadsbuttoncomm">{t("BANKINGCHILD.TEXT29")}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
              :
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt={' '} />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">{t("BANKINGCHILD.TEXT27")} </h5>
                </div>
              </div>

            }
            <div className="showallbtntoo ">
                <a
                  href="/searchExpert"
                  className=""
                  style={{ textDecoration: "none" }}
                >
                  Show All
  </a>
              </div>
            
          </div>

        </div>
      </section>
      <section className="homepageproductoffer realEquestionbanner  bankingseven  responsived">
        <div className="container-fluid">
          <div className="realEquestion">
            <div className="realEquestiontext ">
              <h4> {t("BANKINGCHILD.TEXT18")}</h4>
              <h2 className="resstext">{t("BANKINGCHILD.TEXTONE19")}</h2>
              <h3 className=""
              > {t("BANKINGCHILD.TEXT20")}</h3>
              <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                <div className="realEbuttonmain realEmargin askbuttoncomm">
                  <p className="realEbutton"> {t("BANKINGCHILD.TEXT22")}</p>
                  <img src={iconaskbtn} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="homepageproductoffer blogdetailsexpertppv  realEblogbg  bankingeight">
        <div className="container-fluid">
          <div className="realEbloginner resfont">
            <h2 className="text-center"
            > {t("BANKINGCHILD.TEXT21")}</h2>
            <p className="text-center mt-3 realEblogtext"> {t("BANKINGCHILD.TEXTONE21")}
            </p>
            <p>  {t("BANKINGCHILD.TEXTTHREE21")}</p>
          </div>
          <div className="row mt-5 realEbloglog">
            {
              blogData?.body?.length > 0 ?
                blogData?.body?.slice(0, visibleBlogs).map(blog => {
                  return <div className="col-lg-6 col-md-6 col-sm-6 p-0 minheightmorgage">
                    <a onClick={() => { setBlog(blog); setOpenModal(true) }} >
                      <div className="bloginner  blogsmain text-center">
                        <div className="bloginnerimage"><img className="blogsimgimg" src={blog?.image} alt={' '} /></div>
                        <div className="bloginnertag displaynone">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                        <div className="bloginnertext ">{blog?.title}</div>
                        <div className="bloginnerdate ">{blog?.short_content}</div>
                      </div>

                    </a>

                  </div>
                }) :

                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={' '} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5>You don't have any Blogs yet. </h5>
                  </div>
                </div>
            }
            {!visibleBlogs ? (
               <div className="latestbutton margintoptop minuspace">
               <button href="#" className="bankingviewallbuttoncomm" onClick={handleViewAll} style={{ textDecoration: "none" }}>{t("BANKINGCHILD.TEXT23")}</button>
             </div>
              
            ) : (
              <div className="latestbutton margintoptop minuspace ">
              <button href="#" className="bankingviewallbuttoncomm" onClick={handleViewAll} style={{ textDecoration: "none" }}>{t("BANKINGCHILD.TEXT23")}</button>
            </div>
             
            )}
          </div>
        </div>
      </section>
      <section className="homecustom">

        <Footer />

        {
          openModal &&
          <div className="modalspecial blog-modal">
            <div>
              <div className="mybtn">
                <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                  preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                    fill="#ffffff" stroke="none">
                    <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                  </g>
                </svg></button>
              </div>
              <div className="modal-header text-center">
                <h3>{blog?.title}</h3>
              </div>
              <div className="modal-body text-center">
                <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                </div>
                <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
              </div>
            </div>
          </div>

        }


      </section>
      </section>
    </>

  );
};

export default BankingJsx;