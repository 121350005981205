import React, { useEffect, useState } from 'react'
import { Footer } from '../../common/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { uploadURL, NEW_URL } from '../../../constants/applicationConstants';
import dropbox from "../../../assets/images/dropbox.svg";
import cross from "../../../assets/images/cross.svg";
import Realestatebanner from "../../../assets/images/Realestatebanner.svg";
import { useLocation } from 'react-router-dom';
import "../realEstate/realEstate.css";
import "../realEstate/realEstateresponsive.css";
import borderbottom from "../../../assets/realEstateimages/Rectangle4965.png";
import realEaskicon from "../../../assets/realEstateimages/iconaskbtn.svg"
import { useTranslation } from 'react-i18next';
import toprealE1 from "../../../assets/realEstateimages/toprealE1.png";
import toprealE2 from "../../../assets/realEstateimages/toprealE2.png";
import toprealE3 from "../../../assets/realEstateimages/toprealE3.png";
import toprealEttextline from "../../../assets/realEstateimages/toprealEttextline.png";
import RealEtext from "../../../assets/realEstateimages/RealEtext.png";
import Maskgroup1 from "../../../assets/realEstateimages/Maskgroup1.png";
import Maskgroup2 from "../../../assets/realEstateimages/Maskgroup2.png";
import Maskgroup3 from "../../../assets/realEstateimages/Maskgroup3.png";
import adsby1 from "../../../assets/realEstateimages/adsby1.png";
import expertuiserborder from "../../../assets/realEstateimages/expertuiserborder.png";
import expertuser1 from "../../../assets/realEstateimages/expertuser1.png";
import expertuser2 from "../../../assets/realEstateimages/expertuser2.png";
import expertuser3 from "../../../assets/realEstateimages/expertuser3.png";
import expertuser4 from "../../../assets/realEstateimages/expertuser4.png";
import expertuser5 from "../../../assets/realEstateimages/expertuser5.png";



const RealEstateJsx = (props) => {
  const { t } = useTranslation();
  const {
    offerData,
    userData,
    adData,
    expertData,
    instituteData,
    blogData,
    onTouchHandler,
    onInputChangeHandler,
    onOfferSearchButtonClick,
    onAdSearchButtonClick,
    onExpertClick,
    onInstituteClick,
    subCategoryData
  } = props;
  const [visibleBlogs, setVisibleBlogs] = useState(4);

  const handleViewAll = () => {
    setVisibleBlogs(blogData?.body?.length);
  };



  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    centerMode: false,
    centerPadding: '20px',
    arrows: true,
    //  initialSlide:2    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState(null);

  // {t("PRINT.TITLE")}


  var Institutions = {
    dots: false,
    infinite: true,
    arrows:true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var Options = {
    dots: false,
    infinite: true,
    arrows:true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var Adsby = {
    dots: false,
    infinite: true,
    arrows:true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var Experts = {
    dots: false,
    arrows:false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  return (
    <>
<section className="newrealestateui">
      <section className="realEstateBanner realestateone">
        <div className="container makethecontainer">
          <div className="row">
            <div className="col-lg-12 col-md-6 col-12 realEinner">
              <div className=" realestatefont borderbottom ">
                <h2 >{t("REALESTATE.TEXT1")}</h2>
                <img className="borderimg" src={borderbottom} alt="" />
                <h6 className="h6_style "><b className="realEbold">{t("REALESTATE.TEXT2")}</b>{t("REALESTATE.TEXTONE2")}<b className="realEbold"> {t("REALESTATE.TEXT3")} </b>{t("REALESTATE.TEXTONE3")}<br /></h6>
                <p>{t("REALESTATE.TEXT4")}<span>{t("REALESTATE.TEXTTWO4")}</span>{t("REALESTATE.TEXTTHREE4")}
                  <span>{t("REALESTATE.TEXTFOUR4")}</span> {t("REALESTATE.TEXTFIVE4")}</p>
                <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                  <div className="realEbuttonmain realEhero askbuttoncomm">
                    <p className="realEbutton">{t("REALESTATE.TEXT25")}</p>
                    <img src={realEaskicon} alt="" />
                  </div>
                </Link>
                <p className="realestateherotext">{t("REALESTATE.TEXTSIX5")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="homepageproductoffer  institumainsection  realestatetwo" id="homerealestate">
        <div className="container">

          <div className="letest-inner">
            <h2>{t("REALESTATE.TEXT5")}</h2>
            <h6>{t("REALESTATE.TEXT6")}<span>{t("REALESTATE.TEXTONE6")}</span>
              <br />{t("REALESTATE.TEXT7")} <span>{t("REALESTATE.TEXTONE7")}</span> {t("REALESTATE.TEXTTWO7")}
              <br />{t("REALESTATE.TEXTTHREE7")}<span>{t("REALESTATE.TEXTFOUR7")}</span>{t("REALESTATE.TEXTFIVE7")}</h6>
          </div>
          <div className="row offersss">
            {offerData?.length > 0 ? (
              <Slider {...settings}>
                {offerData.map((offer) => {
                  return (
                    <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 ">
                      <div className="offer-insurence-inner">
                        <div className="offer-image">
                          <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />
                        </div>
                        <div className="offer-name disfontsmall">
                          {offer?.offerName}
                        </div>
                        <div className="posbuttontf d-flex">
                          <a
                            href={offer?.externalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className=" text-center applynowbuttoncomm textcolors"
                          >
                            {t("REALESTATE.TEXT29")}
                          </a>
                          <Link
                            to={{
                              pathname: "/offers",
                              state: { data: offer, frompage: "homerealestate", pagePath: "/realEstate" },
                            }}
                            className="viewdetailsbuttoncomm text-center ctanewtwobutton"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {t("REALESTATE.TEXT30")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !!</h5>
                </div>
              </div>
            )}
            <div className="latestbutton">
              <Link to={'/offers/5'}  className="viewallbuttoncomm" style={{ textDecoration: "none" }} >{t("REALESTATE.TEXT24")}</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="TopRealEstateProducts  Options realestatethree">
        <div className="container">
          <div className="TopRealEstateProducts-inner">
            <h2>{t("REALESTATE.TEXT8")}</h2>
            <h6>{t("REALESTATE.TEXT9")}<span> {t("REALESTATE.TEXTONE9")}</span>{t("REALESTATE.TEXTTOO9")}
      
              <br /> {t("REALESTATE.TEXTTWO9")}</h6>
          
         </div>
         
          <div className="row   offersss">
            {offerData?.length > 0 ? (
              <Slider {...settings}>
                {offerData.map((offer) => {
                  return (
                    <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 ">
                      <div className="offer-insurence-inner">
                        <div className="offer-image">
                        <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />
                        </div>
                        <div className="colinstititeinnername text-center">{offer?.offerName}</div>
                        <div className="posbuttontf d-flex">
                          <a
                            href={offer?.externalUrl}
                            target="_blank"
                            rel="noopener noreferrer textcolors"
                            className=" text-center applynowbuttoncomm"
                          >
                            {t("REALESTATE.TEXT29")}
                          </a>
                          <Link
                            to={{
                              pathname: "/detailedOffer",
                              state: { data: offer, frompage: "homerealestate", pagePath: "/realEstate" },
                            }}
                            className="viewdetailsbuttoncomm text-center ctanewtwobutton"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {t("REALESTATE.TEXT30")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !!</h5>
                </div>
              </div>
            )}
            <div className="latestbutton">
              <button href="#" className="viewallbuttoncomm" style={{ textDecoration: "none" }} >{t("REALESTATE.TEXT24")}</button>
            </div>

          
          </div>
        </div>
      </section>

      <section className="homepageproductoffer Adsby realestatefour">
        <div className="container">
          <h2 className="text-center letestadsheading">{t("REALESTATE.TEXT10")}</h2>
          <h6 className="h6_style text-center realeheading">{t("REALESTATE.TEXT11")}<br />{t("REALESTATE.TEXT12")}</h6>
          
          <div className="row  offersss">
      {adData?.length > 0 ? (
        <Slider {...settings}>
          {adData.map((data,offer) => (
            <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 ">
              <div className="offer-insurence-inner">
                <div className="offer-image">
                <img src={`${NEW_URL}/${data?.imageUrl}`} alt={' '} />
                </div>
                <div className="offer-name  disfontsmall">
                {data?.subInstitutionCategory?.name}
                </div>
                <div className="posbuttontf d-flex">
                  <a
                    href={offer?.externalUrl}
                    target="_blank"
                    rel="noopener noreferrer textcolors"
                    className="text-center applynowbuttoncomm"
                  >
                    {t("REALESTATE.TEXT29")}
                  </a>
                  <Link
                    to={{
                      pathname: "/detailedOffer",
                      state: { data: offer, frompage: "homerealestate", pagePath: "/realEstate" },
                    }}
                    className="viewdetailsbuttoncomm text-center ctanewtwobutton"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("REALESTATE.TEXT30")}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="dropdefault text-center">
          <div className="dropdefaultimage">
            <img src={dropbox} alt="No offers available" />
          </div>
          <div className="dropdefaulttext">
            <h5 className="opacity0">No Record Found !!</h5>
          </div>
        </div>
      )}
      <div className="latestbutton">
        <Link to="/allOffers" className="viewallbuttoncomm" style={{ textDecoration: "none" }}>
          {t("REALESTATE.TEXT24")}
        </Link>
      </div>
    </div>
    </div>
      </section>

      <section className="ToprealE toptoprealestate realestatefive">
        <div className="container">
          <div className="row toprealEflexflow" >
          <div className="col-lg-5 col-12 p-0 ">
              <div className="toprealEmain">
                <div className="row ">
                  {instituteData?.length > 0 ? (
                    instituteData.map((institute, index) => (
                      <div className="col-lg-6 col-sm-4 " key={index}>
                        <div
                          className="colinstititeinner"
                          onClick={() => onInstituteClick(institute)}
                        >
                          <div className="colinstititeinnerimage text-center">
                            <img
                              src={`${NEW_URL}/${institute?.imageUrl}`}
                              alt={institute?.name || "Institute Image"}
                            />
                          </div>
                          <div className="colinstititeinnername text-center institute-text">
                            {institute?.name}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="dropdefault text-center">
                      <div className="dropdefaultimage">
                        <img src={dropbox} alt="No records" />
                      </div>
                      <div className="dropdefaulttext">
                        <h5 className="opacity0">No Record Found !!</h5>
                      </div>
                    </div>
                  )}


                </div>

              </div>
              <div className="showallbtn text-center mt-3">
                <a
                  href="/searchInstitute"
                  className="toprealEbutton"
                  style={{ textDecoration: "none" }}
                >
                  Show All
  </a>
              </div>

            </div>
            <div className="col-lg-7 col-12 p-0">
              <div className="toprealErighttext">
                <h2>Top <span><img className="toprealEtext" src={RealEtext} alt="" /></span> Institutions in <span className="canada">Canada</span></h2>
                <img className="toprealEborder" src={toprealEttextline} alt="" />
                <p>Are you looking to buy, sell, or invest in real estate? <span className="topsubtext"> RIMIB </span> has partnered with <span className="topsubtext"> Canada's top real estate institutions</span> to offer you the best solutions.  Check out their latest offerings today and improve your financial choices. </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="homepageproductoffer mt-5 mb-5">
        <div className="container">
          <h2 className="h2_style text-center">Top Real Estate Institutions in Canada</h2>
          <h6 className="h6_style text-center fw-lighter">Are you looking to buy, sell, or invest in real estate? RIMIB has partnered with Canada's top real estate institutions to provide you with the best solutions for your real estate needs. They will guide you through the entire process, giving you a comprehensive view of all your options so you can make informed decisions and achieve your real estate goals. From buying your dream home to investing in rental properties, leverage their knowledge and expertise to help you succeed.</h6>

          <div className="row">
            {
              instituteData?.length > 0 ? instituteData?.map(institue => {
                return <div className="col-lg-4 col-sm-4 mt-5">
                  <div className="colinstititeinner" onClick={() => onInstituteClick(institue)}>
                    <div className="colinstititeinnerimage text-center"><img src={`${NEW_URL}/${institue?.imageUrl}`} alt={' '} /></div>
                    <div className="colinstititeinnername text-center">{institue?.name}</div>
                  </div>
                </div>
              }) :
                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={' '} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5 className="opacity0">No Record Found !! </h5>
                  </div>
                </div>
            }
          </div>
        </div>

      </section> */}


      <section className="homepageproductoffer bestdeals Experts realestatesix">
        <div className="container-fluid">
          <h2 className="text-center expertheading">Top Real Estate Experts in Canada</h2>
          <h5 className="text-center  h5_style expertsub1">Connect with licensed real estate experts and get the best deals. <br />
          </h5>
          <p className="expertsub2">Message your requirements today and get a tailored response!</p>
         <div className=" p bestdealsbox bestexperts">
            {expertData?.length > 0 ?
              <Slider {...Experts}>
                {expertData?.map(data => {
                  return (
                    <div class="mt-5">
                    
                      <div className="Expertsitmes" onClick={() => onExpertClick(data?.slugUrl)}>
                        <div className="Expertstop"><img src={`${NEW_URL}/${data?.imageUrl}`} alt={' '} />
                        </div>
                        <div className="Expertsbottom Expertstext text-center">
                          <div className="Expertstext"><h5>{`${data.firstName} ${data.lastName}`}</h5> <img src={expertuiserborder} alt="" /></div>
                          <p>{data?.description}</p>
                          <div className="Expertsbutton">
                            <div className="Expertsbuttoni">
                              <a href="" className="messagemebuttoncomm">{t("REALESTATE.TEXT27")}</a>
                              <a href="" className="myadsbuttoncomm">{t("REALESTATE.TEXT28")}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  )
                })}
              </Slider>
              :
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt={' '} />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !! </h5>
                </div>
              </div>

            }
            <div className="latestbutton  marginbottom">
              <a href="/searchExpert" className="viewallbuttoncomm" style={{ textDecoration: "none" }}>{t("REALESTATE.TEXT26")}</a>
            </div>
          </div>

        </div>
      </section>
      <section className="homepageproductoffer  realestateseven">
        <div className="container-fluid">
          <div className="realEquestion">
            <div className="realEquestiontext">
              <h4>{t("REALESTATE.TEXT19")}</h4>
              <h2><span>{t("REALESTATE.TEXTTWO19")}</span> {t("REALESTATE.TEXTTHREE19")}<span>{t("REALESTATE.TEXTFOUR19")}</span>{t("REALESTATE.TEXTFIVE19")}</h2>
              <h3 className="">{t("REALESTATE.TEXT20")}</h3>
              <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                <div className="realEbuttonmain realEmargin askbuttoncomm">
                  <p className="realEbutton">{t("REALESTATE.TEXT25")}</p>
                  <img src={realEaskicon} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="homepageproductoffer blogdetailsexpertppv   realestateeight">
        <div className="container-fluid">
          <div className="realEbloginner">
            <h2 className="text-center">{t("REALESTATE.TEXT21")}</h2>
            <p className="text-center realEblogtext">{t("REALESTATE.TEXT22")}
            </p>
            <p> {t("REALESTATE.TEXT23")}</p>
            <p> {t("REALESTATE.TEXTONE23")}</p>
          </div>
          <div className="row realEbloglog">
            {
              blogData?.body?.length > 0 ?
                blogData?.body?.slice(0, visibleBlogs).map(blog => {
                  return <div className="col-lg-6 col-md-6 col-sm-6 minheightrealestate">
                    <a onClick={() => { setBlog(blog); setOpenModal(true) }} >
                      <div className="bloginner  blogsmain text-center">
                        <div className="bloginnerimage "><img className="blogsimgimg" src={blog?.image} alt={' '} /></div>
                        <div className="bloginnertag  displaynone">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                        <div className="bloginnertext ">{blog?.title}</div>
                        <div className="bloginnerdate ">{blog?.short_content} </div>
                      </div>

                    </a>

                  </div>
                }) :

                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={' '} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5>You don't have any Blogs yet. </h5>
                  </div>
                </div>
            }

    
            {!visibleBlogs ? (
               <div className="latestbutton space-remove">
               <button href="#" className="viewallbuttoncomm" onClick={handleViewAll} style={{ textDecoration: "none" }}>{t("REALESTATE.TEXT24")}</button>
             </div>
              
            ) : (
              <div className="latestbutton space-remove">
              <button href="#" className="viewallbuttoncomm" onClick={handleViewAll} style={{ textDecoration: "none" }}>{t("REALESTATE.TEXT24")}</button>
            </div>
             
            )}
          </div>
        </div>
      </section>
      <section>

      </section></section>
      <Footer />

      {
        openModal &&
        <div className="modalspecial blog-modal">
          <div>
            <div className="mybtn">
              <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                  fill="#ffffff" stroke="none">
                  <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                </g>
              </svg></button>
            </div>
            <div className="modal-header text-center">
              <h3>{blog?.title}</h3>
            </div>
            <div className="modal-body text-center">
              <div className="blog-image-dv"><img src={blog.image} alt={' '} />
              </div>
              <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
            </div>
          </div>
        </div>

      }


    </>

  );
};

export default RealEstateJsx;
