import React from 'react'
import { useEffect } from 'react';
import faqimage from '../../../../assets/images/faqimage.PNG'
import subcaticon from "../../../../assets/images/subcaticon.svg"
import { Footer } from '../../../common/footer/footer';
import { getIconOne } from '../askTheExpert.jsx';
import clipboard from "../../../../assets/images2/clipboard.svg";

const ConfirmAskTheExpertJsx = (props) => {
  const {
    category,
    subCategory,
    subCategoryData,
    question,
    onEditButtonClick,
    onConfirmAndSubmitButtonClick,
    closeModalEdit
  } = props;
  useEffect(() => {
    window.scrollTo(0, 3200);
  }, [])
  
  return (
    <>
<div className="asktheexpertconfirmpopup popupouter mb-5">
  <div className="popup">
     <div className="row">
       <div className="col-lg-7 col-sm-7">
         <div className="cliparttext">
           <h2>{question}</h2>
           <div className="clipartmain">
             <div className="cliparttextbox">
               <p className="text-categ">Category</p>
               <div className="cliparttextboxinner">
               <img src={getIconOne(category)} alt="" /></div>
             </div>
            
             {subCategory &&
            <div className="cliparttextboxouter"> <p>Sub-Category</p>
             <div className="cliparttextbox cliparttextboxtwo">
              <div className="iconbase">
               <img src={`/${(subCategory).replace(/\s/g,'')}.png`} alt="" /></div>
                <p>{subCategory}</p>
             </div>
             </div>
}
           </div>
           <div className="clipartbtn mt-4">
             <a onClick={closeModalEdit} className="clipartbutton">Edit</a>
             <a onClick={onConfirmAndSubmitButtonClick} className="clipartbutton2">Confirm & Submit</a>
           </div>
         </div>

       </div>
       <div className="col-lg-5 col-sm-5">
         <div className="clipart"><img src={clipboard} /></div>
       </div>
     </div>
   </div>
   </div>
     

    </>

  );
};

export default ConfirmAskTheExpertJsx;