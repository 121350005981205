import React from 'react'
import { Component } from 'react';
import GlobalMainSearchOfferJsx from './globalMainSearchOffer.jsx'

export default class GlobalMainSearchOffer extends Component{
    render(){
        return(
            <div>
                <GlobalMainSearchOfferJsx />
            </div>
        )
    }
}