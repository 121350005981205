import React, { useEffect, useState, Component } from 'react'
import { useTranslation } from "react-i18next";
import realestateicon from "../../../assets/images2/realestateicon.svg";
import insurenceicon from "../../../assets/images2/insurenceicon.svg";
import mortgageicon from "../../../assets/images2/mortgageicon.svg";
import bankingicon from "../../../assets/images2/bankingicon.svg";
import investmenticon from "../../../assets/images2/investmenticon.svg";
import realestateicontwo from "../../../assets/images2/realestateicontwo.svg";
import insurenceicontwo from "../../../assets/images2/insurenceicontwo.svg";
import mortgageicontwo from "../../../assets/images2/mortgageicontwo.svg";
import bankingicontwo from "../../../assets/images2/bankingicontwo.svg";
import investmenticontwo from "../../../assets/images2/investmenticontwo.svg";
import videoimage from "../../../assets/images/videoimage.svg";
import videopop from "../../../assets/images/videopop.svg";
import { Footer } from '../../common/footer/footer';
import 'react-awesome-slider/dist/styles.css';
import InputMask from 'react-input-mask';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NEW_URL } from '../../../constants/applicationConstants';

import banner1 from "../../../assets/images/bannermain.svg";
import userbannerimg from "../../../assets/images2/userimg.svg";
import clipboard from "../../../assets/images2/clipboard.svg";
import userbannerimgmobile from "../../../assets/images2/circle_with_image_mobile.svg";
import howitworkicon1 from "../../../assets/images/image183.png";
import howitworkicon2 from "../../../assets/images/image184.png";
import howitworkicon3 from "../../../assets/images/image186.png";
import chooseimg1 from "../../../assets/images/ic-real-estate-flat.svg";
import chooseimg2 from "../../../assets/images/icon2.svg";
import chooseimg3 from "../../../assets/images/icon3.svg";
import chooseimg4 from "../../../assets/images/icon4.svg";
import chooseimg5 from "../../../assets/images/icon5.svg";
import rimg from "../../../assets/images2/r.png";
import rtext from "../../../assets/images2/rtext.png";
import iimg from "../../../assets/images2/iimage.png";
import itext from "../../../assets/images2/itext.png";
import i2img from "../../../assets/images2/i2img.png";
import i2text from "../../../assets/images2/i2text.png";
import mimg from "../../../assets/images2/mimg.png";
import mtext from "../../../assets/images2/mtext.png";
import bimg from "../../../assets/images2/bimg.png";
import btext from "../../../assets/images2/btext.png";
import tooltipicon from "../../../assets/images/Vector170.png";
import Slider from "react-slick";
import questionicon1 from "../../../assets/images/questioniocn1.png";
import { IoClose } from "react-icons/io5";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDownLong } from "react-icons/fa6";
import fullname from "../../../assets/images2/FullName.svg";
import postal from "../../../assets/images2/location.png";
import email from "../../../assets/images2/EmailAddress.svg";
import call from "../../../assets/images2/call.png";
import feequentlyicon1 from "../../../assets/images2/image196.png";
import feequentlyicon2 from "../../../assets/images2/faqsearch.svg";
import feequentlyicon3 from "../../../assets/images2/faquser.svg";
import feequentlyicon4 from "../../../assets/images2/faqquestion.svg";
import feequentlyicon5 from "../../../assets/images2/faqlaptop.svg";
import feequentlyicon6 from "../../../assets/images2/faq3user.svg";
import feequentlyicon7 from "../../../assets/images2/faqqa.svg";
import feequentlyicon8 from "../../../assets/images2/faqcat.svg";
import chooseicon1 from "../../../assets/images2/purchasemobile.png";
import chooseicon2 from "../../../assets/images2/sale.png";
import chooseicon3 from "../../../assets/images2/rentals.png";
import chooseicon4 from "../../../assets/images2/precon.png";
import chooseicon5 from "../../../assets/images2/housesvgrepo.png";
import chooseicon6 from "../../../assets/images2/alternative.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './askTheExpert.style.css';
import './askresponsive.css';
import faqicon1 from "../../../assets/images2/faqicon1.png";
import faqicon2 from "../../../assets/images2/faqicon2.svg";
import faqicon3 from "../../../assets/images2/faqicon3.svg";
import faqicon4 from "../../../assets/images2/faqicon4.svg";
import faqicon5 from "../../../assets/images2/faqicon5.svg";
import faqicon6 from "../../../assets/images2/faqicon6.svg";
import faqicon7 from "../../../assets/images2/faqicon7.svg";
import faqicon8 from "../../../assets/images2/faqicon8.svg";
import clipartimg1 from "../../../assets/images2/clipartimg1.png"
import clipartimg2 from "../../../assets/images2/clipartimg2.png"
import ConfirmAskTheExpertJsx from './confirmAskTheExpert/confirmAskTheExpert';
import FinalAskTheExpertJsx from './finalAsktheExpertScreen/finalAskTheExpertScreen';



export const getIconOne = (label) => {
  if (label === 'Real Estate')
    return realestateicon
  else if (label === 'Insurance')
    return insurenceicon
  else if (label === 'Mortgage')
    return mortgageicon
  else if (label === 'Investment')
    return investmenticon
  else if (label === 'Banking')
    return bankingicon

  else return ''
}

const AskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    onCategoryClick,
    closeModalEdit,
    subsOpen,
    openFinal,
    reviewOpen,
    categoryName,
    subCategoryData,
    onSubcategoryClick,
    subCategoryName,
    questionAsked,
    resetState,
    formData,
    onInputChange,
    onTextAreaChange,
    onTouchHandler,
    formError,
    formSubmitButtonDisable,
    onQuestionClick,
    onSubmitButtonClick,
    expertQuestions,
    onclickFocus,
    purchase
  } = props;
  
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const getMuskPattern = (name) =>
    name === 'phone' ? '(999) 999 9999' : 'a9a 9a9'

  const getIconTwo = (label) => {
    if (label === 'Real Estate')
      return realestateicontwo
    else if (label === 'Mortgage')
      return mortgageicontwo
    else if (label === 'Insurance')
      return insurenceicontwo
    else if (label === 'Banking')
      return bankingicontwo
    else if (label === 'Investment')
      return investmenticontwo
    else return ''
  }

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };
  const [isPopupOpen, setIsPopupOpen] = useState("close");

  useEffect(() => {
    setIsPopupOpen("open");
  }, []);

  const handleClose = () => {
    setIsPopupOpen("close");
  };
  const handleOpenPopup = () => {
    setIsPopupOpen("open");
    // onclickFocus("ask");
  };
  document.addEventListener('DOMContentLoaded', function () {
    const inputGroups = document.querySelectorAll('.input-group-reg');

    inputGroups.forEach(function (inputGroup) {
      const inputField = inputGroup.querySelector('.form-control');

      inputField.addEventListener('focus', function () {
        inputGroup.classList.add('focused');
      });

      inputField.addEventListener('blur', function () {
        inputGroup.classList.remove('focused');
      });
    });
  });
  const [choosecat, setChoosecat] = useState(
    [
      {
        chooseicon: chooseicon1,
        choosetext: "Purchase",
      },
      {
        chooseicon: chooseicon2,
        choosetext: "Sale",
      },
      {
        chooseicon: chooseicon3,
        choosetext: "Rentals",
      },
      {
        chooseicon: chooseicon4,
        choosetext: "Pre-construction properties",
      },
      {
        chooseicon: chooseicon5,
        choosetext: "Custom homes",
      },
      {
        chooseicon: chooseicon6,
        choosetext: "Alternative real estate",
      },
    ]
  )
  const [frequently, setFrequently] = useState(
    [
      {
        frequentlyicon: feequentlyicon1,
        frequentlyheading: "What is Ask The Expert?",
        frequentlytext: "It's a feature that allows you to ask financial questions. All your queries are resolved by accredited domain experts without any charge or fee.",
      },
      {
        frequentlyicon: feequentlyicon2,
        frequentlyheading: "How to submit a question through Ask the Experts?",
        frequentlytext: "Go to “Ask the Expert”",
        frequentlytextli1: "Select the “Main Category” for your query",
        frequentlytextli2: "Select the “Subcategory” of your query",
        frequentlytextli3: "Type your specific question or modify the selected common question as per your need",
        frequentlytextli4: "Click on Submit.",
      },
      {
        frequentlyicon: feequentlyicon3,
        frequentlyheading: "Can we choose our preferred experts to get answers?",
        frequentlytext: "NO. This option is not available in “Ask the Experts”.However, you can search for your preferred experts using “Search the Expert”. All you have to do is enter your postal code. Post this, you will get a complete list of experts located near you.",
      },
      {
        frequentlyicon: feequentlyicon4,
        frequentlyheading: "What information do I  have to give while asking questions?",
        frequentlytext: "You are only required to submit:",
        frequentlytextli1: "Your Name",
        frequentlytextli2: "Email address",
        frequentlytextli3: "Phone No.and",
        frequentlytextli4: "Postal Code",
      },
      {
        frequentlyicon: feequentlyicon5,
        frequentlyheading: "How will I know if my question has been submitted?",
        frequentlytext: "You will be notified via email that your query has been submitted.",
      },
      {
        frequentlyicon: feequentlyicon6,
        frequentlyheading: "Who are experts answering our questions?",
        frequentlytext: "All your queries are answered by experts, who are certified finance professionals with extensive experience in their fields. They have requisite knowledge, skills and understanding to offer you the best resolutions.",
      },
      {
        frequentlyicon: feequentlyicon7,
        frequentlyheading: "Do I need to pay to get my questions answered?",
        frequentlytext: "NO. We do not charge anything from our customers for using “Ask the Expert”. You can seek answers to all your financial queries for free.",
      },
      {
        frequentlyicon: feequentlyicon8,
        frequentlyheading: "Can I submit questions in more than one category?",
        frequentlytext: "YES. Our customers can ask multiple queries in different categories. There is no limit on number of query submissions.",
      },
    ]
  );


  const Subcatslider = {

    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    vertical: true,
    verticalSwiping: true,
    dot: true,
  };
  return (
    <>
      {/* <section className="main-popup" style={{ position: "relative" }}>
        <div className="container">
          {isPopupOpen === "open" && (
            <div className="popup">
              <div className="popup-inner">
                <div className="popup-inner-text">
                  <h2>{t("ASKTHEEXPERT.POPUPHEADING")}</h2>
                  <p>{t("ASKTHEEXPERT.POPUPSUBTEXT")}<span>{t("ASKTHEEXPERT.POPUPSUBTEXT1")}</span>{t("ASKTHEEXPERT.POPUPSUBTEXT2")}</p>
                  <h3>{t("ASKTHEEXPERT.POPUPTEXT1")}<a href="/" className="signuptext">{t("ASKTHEEXPERT.POPUPTEXT2")}</a></h3>
                </div>
                <div className="closebtnicon" onClick={handleClose}>
                  <IoClose size={24} />
                </div>
                <div className="popupbutton text-center d-flex  justify-content-evenly mt-4">
                  <a href="javascript:void(0)" className="popupbtn1">Ask from Dashboard</a>
                  <a href="javascript:void(0)" className="popupbtn2">Continue here</a>
                </div>
              </div>
            </div>
          )}
        </div>
      </section> */}
      {isPopupOpen === "open" && (
        <div className="popupouter">
          <div className="popup">
            <div className="popup-inner">
              <div className="popup-inner-text">
                <h2>{t("ASKTHEEXPERT.POPUPHEADING")}</h2>
                <p>{t("ASKTHEEXPERT.POPUPSUBTEXT")}<span>{t("ASKTHEEXPERT.POPUPSUBTEXT1")}</span>{t("ASKTHEEXPERT.POPUPSUBTEXT2")}</p>
                <h3>{t("ASKTHEEXPERT.POPUPTEXT1")}<a href="/customerRegistration" className="signuptext">{t("ASKTHEEXPERT.POPUPTEXT2")}</a></h3>
              </div>
              <div className="closebtnicon" onClick={handleClose}>
                <IoClose size={24} />
              </div>
              <div className="popupbutton text-center d-flex  justify-content-evenly mt-4">
                <a href="/customerLogin" className="popupbtn1">Ask from Dashboard</a>
                <a href="javascript:void(0)" className="popupbtn2" onClick={handleClose}>Continue here</a>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={isPopupOpen === "open" ? "blurred" : ""}>
        <div className="content-popup">
          <section className="herosection heroasktheexpert heronewaskexpert">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <div className="heroleftpanel" data-aostest="fade-right">
                    <h2 className="heroheading">{t("ASKTHEEXPERT.HEADING")} </h2>
                    <h2 className="heroheading2">{t("ASKTHEEXPERT.HEADING2")} </h2>
                    <p className="text-start">{t("ASKTHEEXPERT.SUBHEADING")}<b>{t("ASKTHEEXPERT.SUBHEADINGT1")}</b></p>
                    <p className="subh1">{t("ASKTHEEXPERT.SUBHEADING1")}</p>
                    <div className="heroleftbutton d-flex mt-4">
                      <a href="#ask" className="ctaonebutton" >I want to ask now</a>
                      <a href="#newfaq" className="ctatwobutton">FAQ</a>
                    </div>
                  </div>
                </div>
                <div className="main-round-img d-none d-sm-block">
                  <div className="user-round-img">
                    <img src={userbannerimg} alt="" />
                  </div>
                </div>
                <div className="main-round-img d-none">
                  <div className="user-round-img">
                    <img src={userbannerimgmobile} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="Howitworks mb-5">
            <div className="container">
              <div className="howitworkinner">
                <div className="howitworkinner-text">
                  <h2 className="mb-5">{t("ASKTHEEXPERT.SUBHEADING2")}</h2>
                </div>
                <div className="row">
                  <div className="col-sm-4 col-12 resrrrow ">
                    <div className="howitwork-box  ">
                      <div className="howitworkbox-inner">
                        <img src={howitworkicon1} alt="" />
                      </div>
                      <p className="mt-4 mb-sm-5  ">{t("ASKTHEEXPERT.ULLIHEADING1")}</p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-12 resrrrow ">
                    <div className="howitwork-box " id="howitworkarrow">
                      <div className="howitworkbox-inner" id="howitworkbox2color">
                        <img src={howitworkicon2} alt="" />
                      </div>
                      <p className="mt-4 mb-sm-5  ">{t("ASKTHEEXPERT.ULLIHEADING2")}</p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-12 resrrrow">
                    <div className="howitwork-box  marginres" id="howitworkarrow">
                      <div className="howitworkbox-inner" id="howitworkbox3color">
                        <img src={howitworkicon3} alt="" />
                      </div>
                      <p className="mt-4 mb-sm-5  ">{t("ASKTHEEXPERT.ULLIHEADING3")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="joincanadians">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <div className="jioncanadianstext">
                    <h2 className="mainheadingtext mb-3">{t("ASKTHEEXPERT.SUBHEADING3")}<span>{t("ASKTHEEXPERT.SUBHEADINGTEXT1")}</span>{t("ASKTHEEXPERT.SUBHEADINGTEXT2")}<span>{t("ASKTHEEXPERT.SUBHEADINGTEXT3")}</span>{t("ASKTHEEXPERT.SUBHEADINGTEXT4")}<span>{t("ASKTHEEXPERT.SUBHEADINGTEXT5")}</span></h2>
                    <p className="smalllinespan">{t("ASKTHEEXPERT.SUBHEADING4")}<span> {t("ASKTHEEXPERT.SUBHEADINGQUE4")}</span><span>{t("ASKTHEEXPERT.SUBHEADING5")}</span>{t("ASKTHEEXPERT.SUBHEADINGASK5")}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="SelectMainCategory mt-5 mb-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="categorytext">
                    <h2>{t("ASKTHEEXPERT.SELECT")} <span>{t("ASKTHEEXPERT.CATEGORY")}</span></h2>
                    <p className="catetext1 mt-3 mb-0">{t("ASKTHEEXPERT.TEXT1")}</p>
                    <p className="catetext2">{t("ASKTHEEXPERT.TEXT2")}</p>
                  </div>
                </div>
              </div>
              <div className="choose-main" >
                <div className="choose">
                  <div className="choose-box" onClick={purchase} >
                    <img src={chooseimg1} className="onimg" alt="" />
                    <p>{t("ASKTHEEXPERT.OPTION1")}</p>
                  </div>
                </div>
                <div className="choose">
                  <div className="choose-box">
                    <img src={chooseimg2} className="onimg" alt="" />
                    <p>{t("ASKTHEEXPERT.OPTION2")}</p>
                  </div>
                </div>
                <div className="choose">
                  <div className="choose-box">
                    <img src={chooseimg3} className="onimg" alt="" />
                    <p>{t("ASKTHEEXPERT.OPTION3")}</p>
                  </div>
                </div>
                <div className="choose">
                  <div className="choose-box">
                    <img src={chooseimg4} className="onimg" alt="" />
                    <p>{t("ASKTHEEXPERT.OPTION4")}</p>
                  </div>
                </div>
                <div className="choose">
                  <div className="choose-box">
                    <img src={chooseimg5} className="onimg" alt="" />
                    <p>{t("ASKTHEEXPERT.OPTION5")}</p>
                  </div>
                </div>
              </div>
              <div className="choose-main-sec" >
                <div className="choose-sec">
                  <div className="choose-box-sec" onClick={purchase} >
                    <img src={rimg} className="rimibimg" alt="" />
                    <div className="rtextimg">
                      <img src={rtext} alt="" />
                    </div>
                  </div>
                </div>
                <div className="choose-sec">
                  <div className="choose-box-sec" onClick={purchase} >
                    <img src={iimg} className="rimibimg" alt="" />
                    <div className="rtextimg">
                      <img src={itext} alt="" />
                    </div>
                  </div>
                </div>
                <div className="choose-sec">
                  <div className="choose-box-sec" onClick={purchase} >
                    <img src={mimg} className="rimibimg" alt="" />
                    <div className="rtextimg">
                      <img src={mtext} alt="" />
                    </div>
                  </div>
                </div>
                <div className="choose-sec">
                  <div className="choose-box-sec" onClick={purchase} >
                    <img src={i2img} className="rimibimg" alt="" />
                    <div className="rtextimg">
                      <img src={i2text} alt="" />
                    </div>
                  </div>
                </div>
                <div className="choose-sec">
                  <div className="choose-box-sec" onClick={purchase} >
                    <img src={bimg} className="rimibimg" alt="" />
                    <div className="rtextimg">
                      <img src={btext} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}


          {/* <section className="asktheexpertcategorysection mt-5 mb-5" id="askps">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-5 col-12" style={{ backgroundColor: "#036518" }}>
                  <div className="categoryonesetone">
                    <p className="categoryonesetone-text">
                      {t("ASKTHEEXPERT.SELECT2")}
                      <h4 className="catmargin">{t("ASKTHEEXPERT.CATEGORY2")}</h4>
                    </p>
                    <p>{t("ASKTHEEXPERT.TEXT3")}</p>
                    <p className="categoryonesetone-text-too">{t("ASKTHEEXPERT.TEXT4")}</p>
                  </div>
                </div>
                <div className="col-md-7 col-12 asktheexpertinnerask">
                  <div className="asktheexpertcategorysectioninner asktheexpertcategorysectioninnerline asktheexpertcategorysectioninnerlinenew">
                    <div className="catstart clearfix ml-4 pl-4 mr-5 pr-5" >
                      <div className="categoryonesetfirst row">
                        {choosecat.map((chooseitems) => (
                          <div className="col-md-6 col-6 col-xs-6">
                            <div className="categoryonesettwo">
                              <div className="iconone">
                                <img src={chooseitems.chooseicon} alt={chooseitems.choosetext} />
                              </div>
                              <h5>{chooseitems.choosetext}</h5>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <section className="SelectMainCategory" id="ask" style={{ marginBottom: "10px 0" }}>
            <div className="container-fluid">
              <div className="asktheexpertcategorysectioninner asktheexpertcategorysectioninnerline asktheexpertcategorysectioninnerlinenew">
                <div class="row">
                  <div className="col-12">
                    <div className="categorytext">
                      <h2>{t("ASKTHEEXPERT.SELECT")} <span>{t("ASKTHEEXPERT.CATEGORY")}</span></h2>
                      <p className="catetext1 mt-3 mb-0">{t("ASKTHEEXPERT.TEXT1")}</p>
                      <p className="catetext2">{t("ASKTHEEXPERT.TEXT2")}</p>
                    </div>
                  </div>
                </div>
                <div className="catstart clearfix" data-aostest="fade-up-right">
                  <div className={`categoryonesetfirst ${categoryName && 'categoryoneset'}`}>
                    <div className="choose-main" >
                      {/* <div className="categoryonesetone" >
                    <p>{t("ASKTHEEXPERT.SELECT")}</p>
                    <h4>{t("ASKTHEEXPERT.CATEGORY")}</h4>
                    <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.TEXT1")}</b> {t("ASKTHEEXPERT.TEXT2")}</p>
                  </div> */}
                      {
                        categoryData?.map(catDT => {
                          return <div className={`choose categoryonesettwo ${categoryName === catDT?.name ? 'active' : ''}`} onClick={() => onCategoryClick(catDT?.institutionCategoryId, catDT?.name)}>
                            <div className={`iconone ${categoryName === catDT?.name ? 'active' : ''}`} ><img src={getIconOne(catDT.name)} alt={''} /></div>
                            <div className="icontwo"><img src={getIconTwo(catDT.name)} alt={''} /></div>
                            {/* <h5>{catDT.name}</h5> */}
                          </div>
                        })
                      }
                    </div>
                  </div>
                </div>
                {categoryName && <div className="catstart clearfix" data-aostest="fade-up-left">
                  <div className={`categorytwosetfirsttest ${subCategoryName && 'categorytwoset'}`}>

                    <div id="askts" onClick={(e) => onclickFocus("askQus")}>

                      <div className="subcategoryinneraskexpert mt-5">

                        {
                          subCategoryData?.map(subCatDT => {
                            return <div id="askqw" className={`categoryonesettwo categoryonesetwidth ${subCategoryName === subCatDT?.name ? 'active' : ''}`} onClick={() => onSubcategoryClick(subCatDT.institutionCategoryId, subCatDT.name)}>
                              <div className="iconone"><img src={`${NEW_URL}/${subCatDT.icon}`}
                                alt={''} /></div>
                              <div className="icontwo"><img src={`${NEW_URL}/${subCatDT.icon}`}
                                alt={''} /></div>
                              <h5>{subCatDT.name}</h5>
                            </div>
                          })
                        }

                      </div>
                    </div>
                  </div>
                </div>
                }
                {subCategoryName && <div id="askQus">
                  <div className="container">
                    <div className="row catborderbottom">
                      <div className="col-lg-6 mb-3 d-none d-sm-block">
                        <div className="catstart clearfix" data-aostest="fade-up-bottom">
                          <div className="categoryfourset">
                            <div className="categoryonesetone">
                              <div className="question-text">
                                <h2>{t("ASKTHEEXPERT.QUESTIONHEADING")}<span>{t("ASKTHEEXPERT.QUESTIONHEADING-1")}</span></h2>
                              </div>
                            </div>
                            {/* <AwesomeSlider>
                    {
                      expertQuestions.length > 0 ? expertQuestions.map(que => {
                        return <div onClick={() => onQuestionClick(que)}>{que.question}</div>

                      }) :

                        <div>No Question Found</div>
                    }
                  </AwesomeSlider> */}

                            <Slider {...Subcatslider}>

                              {expertQuestions.length > 0 ? (
                                expertQuestions.map(que => (
                                  <div className="main-question questionslider">
                                    <div className="questionslider-text">
                                      <p>{que.question}</p> </div>
                                    <div className="questiontext-icon">
                                      <img src={questionicon1} key={que.id} onClick={() => onQuestionClick(que)} alt="" />
                                    </div>

                                  </div>

                                ))
                              ) : (
                                <div>No Question Found</div>
                              )}

                            </Slider>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3 mobileblockscroll d-block d-sm-none">
                        <div className="catstart clearfix" data-aostest="fade-up-bottom">
                          <div className="categoryfourset">
                            <div className="categoryonesetone">
                              <div className="question-text">
                                <h2>{t("ASKTHEEXPERT.QUESTIONHEADING")}<span>{t("ASKTHEEXPERT.QUESTIONHEADING-1")}</span></h2>
                              </div>
                            </div>
                            {expertQuestions.length > 0 ? (
                              expertQuestions.map(que => (
                                <div className="main-question questionslider">
                                  <div className="questionslider-text">
                                    <p>{que.question}</p> </div>
                                  <div className="questiontext-icon">
                                    <img src={questionicon1} key={que.id} onClick={() => onQuestionClick(que)} alt="" />
                                  </div>

                                </div>

                              ))
                            ) : (
                              <div>No Question Found</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3 queastio-main">
                        <div className="textarecs mt-5" data-aostest="fade-bottom">
                          <div className="question-text-right">
                            <h2>{t("ASKTHEEXPERT.QUESTIONHEADING1")}<span>{t("ASKTHEEXPERT.QUESTIONTEXT3")}</span></h2>
                            <p className="question-right-ptext">{t("ASKTHEEXPERT.QUESTIONTEXT4")}<span>{t("ASKTHEEXPERT.QUESTIONTEXTSUBTEXT4")}</span></p>
                            <p className="question-right-text-too">{t("ASKTHEEXPERT.QUESTIONTEXT5")}</p>
                          </div>
                          <div className="input-container form-group m-2">
                            <div className="input-question">
                              <textarea className="input form-control textareaform" value={questionAsked} name="message" onChange={onTextAreaChange} placeholder=" "></textarea>
                              <div className="placeholder">Please type your question here... </div>
                            </div>
                            <div className="nextbtn">
                              {!questionAsked ?                     <button className="proceedbtn" disabled>Next</button>

                                : <button className="proceedbtn"><a  href="#nextregister">Next</a></button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </section>
          <section className="register pb-4" id="nextregister">
            <div className="container">
              <div className="register-main">
                <div className="register-main-text">
                  <h2>{t("ASKTHEEXPERT.REGISTERHEADING")}<span>{t("ASKTHEEXPERT.REGISTERHEADING1")}</span></h2>
                  <h3>{t("ASKTHEEXPERT.SIGNUPTEXT")}</h3>
                  <p>{t("ASKTHEEXPERT.SIGNUPTEXT1")}</p>
                  <div className="regbtn text-center mt-5 mb-5">
                    <a href="/customerRegistration" className="registernowbutton" >Register Now</a>
                  </div>
                </div>
                <div className="mainexpforminner mt-5 register-fill">
                  <div className="row" style={{ justifyContent: "space-between" }}>
                    <div className="input-container form-group input-group-reg col-md-6 mb-5  ">
                      <span className="input-group-text"><img src={fullname} alt="" /></span>
                      <input
                        type="text"
                        name='name'
                        value={formData.name}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        className={
                          formError['name']
                            ? "input form-control  "
                            : "input form-control"
                        }
                        placeholder=" " />
                      <div className="placeholder">Full Name* </div>
                      <span className="text-danger">
                        {formError['name'] &&
                          (formError['name'] === "req"
                            ? 'Name is required'
                            : 'Enter a valid Name')}
                      </span>
                    </div>
                    <div className="input-container form-group input-group-reg col-md-6 mb-5">
                      <span className="input-group-text"><img src={email} alt="" /></span>
                      <input
                        type="email"
                        name='email'
                        value={formData.email}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        className={
                          formError['email']
                            ? "input form-control  "
                            : "input form-control"
                        }
                        placeholder=" " />
                      <div className="placeholder">Email Address* </div>
                      <span className="text-danger">
                        {formError['email'] &&
                          (formError['email'] === "req"
                            ? 'Email is required'
                            : 'Enter a valid Email')}
                      </span>
                    </div>
                    <div className="input-container form-group input-group-reg col-md-6 mb-5">
                      <span className="input-group-text"><img src={postal} alt="" /></span>
                      <InputMask mask={getMuskPattern('postalCode')} value={formData.postalCode} name="postalCode" onChange={onInputChange} onBlur={onTouchHandler}>
                        {(inputProps) => <input {...inputProps}
                          type="text"
                          className={
                            formError['postalCode']
                              ? "input form-control  "
                              : "input form-control"
                          }
                          placeholder=" "
                          disableUnderline />}
                      </InputMask>
                      <div className="placeholder">Postal Code* </div>
                      <span className="text-danger">
                        {formError['postalCode'] &&
                          (formError['postalCode'] === "req"
                            ? 'Postal code is required'
                            : 'Enter a valid Postal Code')}
                      </span>
                    </div>
                    <div className="input-container form-group input-group-reg mb-5 col-md-6">
                      <span className="input-group-text"><img src={call} alt="" /></span>
                      <InputMask mask={getMuskPattern('phone')} value={formData.phoneNumber} name="phoneNumber" onChange={onInputChange} onBlur={onTouchHandler}>
                        {(inputProps) => <input {...inputProps}
                          type="tel"
                          className={
                            "input form-control"
                          }
                          placeholder=" "
                          disableUnderline />}
                      </InputMask>
                      <div className="placeholder">Phone Number </div>
                      <span className="text-danger">
                        {formError['phoneNumber'] &&
                          (formError['phoneNumber'] === "req"
                            ? ''
                            : 'Enter a valid Phone Number')}
                      </span>
                    </div>
                  </div>
                  <div className="proceed-main-btn   mb-3 text-end ">
                    <button className="proceedbtn" disabled={formSubmitButtonDisable} onClick={onSubmitButtonClick}>Proceed</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="frequently pt-5 pb-5 mb-5  d-none d-sm-block " id="newfaq">
            <div className="container">
              <div className="row">
                <h2 className="frequently-text mb-5">
                  {t("ASKTHEEXPERT.FREQUENTLYHEADING")}
                  <span>{t("ASKTHEEXPERT.FREQUENTLYHEADING1")}</span>
                </h2>
                {frequently.map((items, index) => {
                  console.log(`Rendering item ${index + 1}`, items);
                  return (
                    <div className="col-lg-3 col-12" key={index}>
                      <div className="frequently-box">
                        <div className="frequenty-box-up">
                          <img src={items.frequentlyicon} className="mb-3" alt="" />
                          <h6 className="mb-2">{items.frequentlyheading}</h6>
                          <div className="icon-down" >
                            <MdKeyboardArrowDown />

                          </div>
                        </div>
                        <div className="frequenty-box-down">
                          <div className="frequently-box-down-text">
                            <p>{items.frequentlytext}</p>
                            <ul>
                              {items.frequentlytextli1 && <li>{items.frequentlytextli1}</li>}
                              {items.frequentlytextli2 && <li>{items.frequentlytextli2}</li>}
                              {items.frequentlytextli3 && <li>{items.frequentlytextli3}</li>}
                              {items.frequentlytextli4 && <li>{items.frequentlytextli4}</li>}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <section className="faqsection d-block d-lg-none d-sm-none d-md-none " style={{ backgroundColor: "#DAF0FF" }} id="faq">
            <div className="container">
              <h2 className="frequently-text mb-5">
                {t("ASKTHEEXPERT.FREQUENTLYHEADING")}
                <span>{t("ASKTHEEXPERT.FREQUENTLYHEADING1")}</span>
              </h2>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header " id="headingOne">
                    <button className="accordion-button faqmobileborder bgnone" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <img src={faqicon1} className="me-3" alt="" /> What is Ask the Expert
      </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>It's a feature that allows you to ask financial questions. All your queries are resolved by accredited domain experts without any charge or fee. </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <img src={faqicon2} className="me-3" alt="" /> How to Submit a Query through Ask the Expert?
      </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>
                        Go to “Ask the Expert” </p>
                      <ul>
                        <li>Select the “Main Category” for your query </li>
                        <li>Select the “Subcategory” of your query </li>
                        <li>Browse the several common questions. You can select any one question.</li>
                        <li>Type your specific question or modify the selected common question as per your need </li>
                        <li>Click on Submit.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <img src={faqicon3} className="me-3" alt="" />  Can we choose our preferred experts to get answers?

      </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p><strong>NO.</strong> This option is not available in "Ask the Expert". <br />

However, you can search for your preferred experts using “Search the Expert”. All you have to do is enter your postal code. Post this, you will get a complete list of experts located near you. </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <img src={faqicon4} className="me-3" alt="" />   What information do I need to provide to have my question answered?

      </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>You are only required to submit:</p>
                      <ul>
                        <li>Your Name </li>
                        <li>Email address </li>
                        <li>Phone No. and </li>
                        <li>Postal Code</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <img src={faqicon5} className="me-3" alt="" />   How will I know if my query has been submitted?
      </button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>You will be notified via email that your query has been submitted.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingSix">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      <img src={faqicon6} className="me-3" alt="" />    Who are the experts answering our queries?

      </button>
                  </h2>
                  <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>All your queries are answered by experts, who are certified finance professionals with extensive experience in their fields. They have requisite knowledge, skills and understanding to offer you the best resolutions. </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingSeven">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      <img src={faqicon7} className="me-3" alt="" />  Do we have to pay to have our questions answered?

      </button>
                  </h2>
                  <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p><strong>NO.</strong> We do not charge anything from our customers for using "Ask the Expert". You can seek answers to all your financial queries for free.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingEight">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                      <img src={faqicon8} className="me-3" alt="" />  Can I submit queries in more than one category?

      </button>
                  </h2>
                  <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p><strong>YES. </strong>Our customers can ask multiple queries in different categories. There is no limit on number of query submissions. </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </div>
      {subsOpen &&
        <ConfirmAskTheExpertJsx
          resetState={resetState}
          closeModalEdit={closeModalEdit}
          openFinal={openFinal}

        />

      }
      {reviewOpen &&
        <FinalAskTheExpertJsx />
      }

      <Footer />
    </>

  );
};

export default AskTheExpertJsx;