import React from 'react'
import { Component } from 'react';
import GlobalMainSearchJsx from './globalMainSearch.jsx'

export default class GlobalMainSearch extends Component{
    render(){
        return(
            <div>
                <GlobalMainSearchJsx />
            </div>
        )
    }
}