import React, { useEffect, useState } from 'react'
import { NEW_URL } from '../../../../constants/applicationConstants';
import dropbox from "../../../../assets/images/dropbox.svg";
import { Pagination } from '../../../common/pagination/pagination';
import moment from 'moment';
import insurancebg from "../../../../assets/images/insurancebg.png";
import RealEstatebg from "../../../../assets/images/RealEstatebg.png";
import mortgagebg from "../../../../assets/images/morgagegebg.png";
import bankingbg from "../../../../assets/images/bankingbg.png";
import investmentbg from "../../../../assets/images/investmentbg.png";

const CustomerQnaAnswerJsx = (props) => {
  const {
    data,onPaginationButtonClick,meta
  } = props;

  
  // const [background, setBackground] = useState('#fff');
  // const colors = ['#B8FFB8', '#90be6d', '#f94144', '#f8961e', '#43aa8b', '#577590'];

  // useEffect(() => {

  //   const hasRealEstate = data.some(list => list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'mortgage');
    
  //   if (hasRealEstate) {
  //     // setBackground(`url(${mortgagebg})`,); 
  //   } else {
  //     // const randomColor = colors[Math.floor(Math.random() * colors.length)];
  //     // setBackgroundColor(randomColor);
  //   }
  // }, [data, colors]); 
 
  return (
    <>
       <section className="alldashboard allcustans">
      <div className="dashboardrightpanelinner  mb-5">
        <div className="questioninner mt-4">
          {data.length ? (
            data.map(list => (
              <div
                className="questioninnerin mb-3"
                style={{ background: list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'mortgage' ?  `url(${mortgagebg})`:list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'banking' ?  `url(${bankingbg})` : list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'real estate' ?  `url(${RealEstatebg})` : list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'insurance' ?  `url(${insurancebg})` : list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'investment' ?  `url(${investmentbg})`:"" ,backgroundSize: 'cover',minHeight:"302px",backgroundRepeat:"no-repeat"}}
                key={list.id}
              >
                <div className="d-flex questioninnerinbottom">
                  <div className="expertquestiondetails">
                    <div className="expertuser">
                      <img
                        src={!list?.expert?.imageUrl
                          ? `https://ui-avatars.com/api/?name=${list?.expert?.firstName}+${list?.expert?.lastName}&rounded=true`
                          : `${NEW_URL}/${list?.expert?.imageUrl}`}
                        alt={`${list?.expert?.firstName} ${list?.expert?.lastName}`}
                      />
                    </div>
                    <div className="expName">
                      <span>{`${list?.expert?.firstName} ${list?.expert?.lastName}`}</span>
                      <div className="questiondate">
                        {moment(list?.answeredAt).format('MM-DD-YYYY hh:mm A')}
                      </div>
                    </div>
                  </div>
                  <div className="expertquestiondetailscategory" style={{ backgroundColor: list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'mortgage' ? '#2F596F' :list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'investment' ? '#86357A' : list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'banking' ? '#2281B8' :list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'real estate' ? '#00831D': "#FF4C00" }}>
                    <span>{list.askExpertQuestionUserResponse.parentInstitutionCategory}</span>
                  </div>
                </div>
                <h5 className="h5_style questiontext">
                  <span>Question:</span> <b>{list.askExpertQuestionUserResponse.question}</b>
                </h5>
                <h5 className="h5_style questiontext">
                  <span>Answer:</span> <b>{list.answer}</b>
                </h5>
              </div>
            ))
          ) : (
            <div className="dropdefault text-center">
              <div className="dropdefaultimage">
                <img src={dropbox} alt="Default" />
              </div>
              <div className="dropdefaulttext">
                <h5>You don't have any answered questions yet.</h5>
              </div>
            </div>
          )}
          {data.length > 0 && (
            <Pagination
              limit={10}
              totalCount={meta?.totalCount || 0}
              onPaginationButtonClick={onPaginationButtonClick}
            />
          )}
        </div>
      </div>
    </section>
    </>

  );
};

export default CustomerQnaAnswerJsx;