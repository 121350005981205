import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';
import OffersJsx from './offers.jsx'
import { nameRegex, postalCodeRegex } from '../../../shared/helper.js';

export default class Offers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            subCategoryData: null,
            formData: {
                name: '',
                postalCode: '',
                category: null,
                subCategory: null
            },
            selectedCategoryId: null,
            subCategories: [],
            checkedCats: [],
            checkedSubCats: [],

            investmentOfferData: null,
            allOffersData: null,
            overallOffers: null,
            topOffers: null,
            mortgageOfferData: null,
            insuranceOfferData: null,
            bankingOfferData: null,
            realestateOfferData: null,
            isProcessing: false,
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            expertData: [],
            page: 1,
        }
    }


    componentDidMount = () => {
        this.getTopOffers();

        this.getcategorySubcategory();
    }

    getcategorySubcategory = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        })
    }
    getAllFeauturedOffersData = () => {
        const selectedType = this.props.match.params.type;
        this.setState({ isProcessing: true })
        // for offer button click
        if (selectedType === "all") {
            axios.get(`${baseURL}/search-offers`).then(res => {
                this.setState({ allOffersData: res.data.data, overallOffers: res.data.data, isProcessing: false })
            })
        } else if (selectedType === "homeFeatured") {
            axios.get(`${baseURL}/homeFeaturedOffers`).then(res => {
                this.setState({ allOffersData: res.data.data, overallOffers: res.data.data, isProcessing: false })
            })
        } else {
            axios.get(`${baseURL}/FeaturedOffers?institutionCategoryId=${selectedType}`).then(res => {
                this.setState({ allOffersData: res.data.data, overallOffers: res.data.data, isProcessing: false })
            })
        }

    }



    getTopOffers = () => {
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/topOffers`).then(res => {
            this.setState({ topOffers: res.data.data, isProcessing: false })
        })
    }


    onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)

        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }
    isFromSubmissionBlock = () => {
        let { postalCode } = this.state.formData;

        if (postalCode) {
            if (
                this.inputRegexValidation('postalCode', postalCode)
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };
    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'category') {
            this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )

        }


        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }
    inputRegexValidation = (name, value) => {
        if (name === 'name')
            return nameRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };

    onSubmitButtonClick = () => {
        const { name, postalCode, category, subCategory } = this.state.formData;
        //   if(category){
        //     this.props.history.push(`/offerListing/${category}/${subCategory}`);

        //   }
        this.props.history.push(`/offerListing/${category}/${subCategory}`);

    }
    filterCatData = () => {
        console.log("ffffff", this.state.subCategories)

        
        const catDataFiltered = this.state.overallOffers.filter(offer =>
            this.state.subCategories.includes(offer.institutionSubCategoryId)
        );
        console.log('ddddddddddddddddddddddddddddddd', this.state.subCategories)
        this.setState({ allOffersData: catDataFiltered })


    }
    filterSubData = () => {
        const subCatDataFiltered = this.state.overallOffers.filter(offer =>
            this.state.checkedSubCats.includes(offer.institutionSubCategoryId)
        );

        // this.setState(prevState => ({
        //     allOffersData: [...prevState.allOffersData, ...subCatDataFiltered] // Spread existing items and add new items
        //   }));
        this.setState({ allOffersData: subCatDataFiltered })

    }

    // if (selectedCategory) {
    //     const abhi = selectedCategory.subInstitutionCategories
    //     const institutionCategoryIds = abhi.map(category => category.institutionCategoryId);

    //     console.log("jjjjjjjjjjjjjjjj", institutionCategoryIds)

    //     this.setState((prevState) => {
    //         if (prevState.subCategories.includes(this.state.subCategories)) {
    //             // If the ID is already selected, remove it
    //             return { subCategories: prevState.subCategories.filter((prevId) => prevId !== institutionCategoryIds) };
    //         } else {
    //             // If the ID is not selected, add it
    //             return { subCategories: [...prevState.subCategories, institutionCategoryIds] };
    //         }
    //     });


    // } else {
    //     this.setState({ subCategories: [] });
    // }
    selectCategory = (data) => {

        // 
        const selectedId = data.institutionCategoryId;
        this.setState({ selectedCategoryId: selectedId });

        const selectedCategory = this.state.categoryData.find(category => category.institutionCategoryId === selectedId);
        if (selectedCategory) {
            const abhi = selectedCategory.subInstitutionCategories
            const institutionCategoryIds = abhi.map(category => category.institutionCategoryId);

            this.setState((prevState) => ({
                subCategories: [...prevState.subCategories, ...institutionCategoryIds],
                institutionCategoryIds: '',
            }));
            //   this.setState({ subCategories: selectedCategory.subInstitutionCategories });
        } else {
            this.setState({ subCategories: [] });
        }
        // 
        // this.setState((prevState) => {
        //     if (prevState.checkedCats.includes(data.institutionCategoryId)) {
        //         // If the ID is already selected, remove it
        //         return { checkedCats: prevState.checkedCats.filter((prevId) => prevId !== data.institutionCategoryId) };
        //     } else {
        //         // If the ID is not selected, add it
        //         return { checkedCats: [...prevState.checkedCats, data.institutionCategoryId] };
        //     }
        // });

        setTimeout(() => {
            this.filterCatData()

        }, 500);
    }
    selectSubCategory = (data) => {
        this.setState((prevState) => {
            if (prevState.checkedSubCats.includes(data.institutionCategoryId)) {
                // If the ID is already selected, remove it
                return { checkedSubCats: prevState.checkedSubCats.filter((prevId) => prevId !== data.institutionCategoryId) };
            } else {
                // If the ID is not selected, add it
                return { checkedSubCats: [...prevState.checkedSubCats, data.institutionCategoryId] };
            }
        });

        setTimeout(() => {
            this.filterSubData()

        }, 500);

        // console.warn("selected sub cat", data,this.state.checkedCats)

    }


    render() {
        const { id } = this.props.match.params;

        return (
            <div>
                <OffersJsx
                    getAllFeauturedOffersData={this.getAllFeauturedOffersData}
                    investmentOfferData={this.state.investmentOfferData}
                    topOffers={this.state.topOffers}
                    mortgageOfferData={this.state.mortgageOfferData}
                    insuranceOfferData={this.state.insuranceOfferData}
                    bankingOfferData={this.state.bankingOfferData}
                    realestateOfferData={this.state.realestateOfferData}
                    allOffersData={this.state.allOffersData}
                    // 
                    categoryData={this.state.categoryData}
                    subCategoryData={this.state.subCategoryData}
                    formData={this.state.formData}
                    onInputChange={this.onInputChange}
                    formError={this.state.formError}
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    selectCategory={this.selectCategory}
                    selectSubCategory={this.selectSubCategory}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}