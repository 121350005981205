import React from 'react'
import { useTranslation } from "react-i18next";
import { useEffect , useState } from 'react';
import 'react-awesome-slider/dist/styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./global.css";

const GlobalJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    onSubmitButtonClick,
    formData,
    onInputChange,
  } = props;


  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

    const [activeButton, setActiveButton] = useState(1);
    const [search, setsearch] = useState('');

    const handleButtonClick = (buttonId) => {
      setActiveButton(buttonId);
    };

  return (
    <>
        <section className="globalOffProSearch pt-3 pb-3">
        <div className="container">
        
          {/* <div className="row search-block">


              <div className="col-sm-3 col-xs-12">
                <div className="off-pro">
                  <button type="submit" className={activeButton === 1 ? 'active' : ''}
                    onClick={() => handleButtonClick(1)} 
                    style={{padding: "10px 30px", borderRadius: "25px"}}>
                      Offers</button>
                  <button type="submit" className={activeButton === 2 ? 'active' : ''}
                    onClick={() => handleButtonClick(2)}
                    style={{ padding: "10px 20px", borderRadius: "25px"}}>
                      Products</button>
                </div>
              </div>

              <div className="col-sm-6 col-xs-12 selection">
                <div className="input-container form-group1">
                  <select className="input form-control1 form-select" placeholder=" "  name="category" 
                    onChange={onInputChange} >
                    <option value="">Category</option>
                    {categoryData?.map((item) => {
                      return (
                        <>
                          <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                        </>
                      ) })}
                  </select>
                </div>

                <div className="input-container form-group1">
                  <select className="input form-control1 form-select" placeholder=" " name="subCategory" value={formData.subCategory}
                    onChange={onInputChange} style={{width: '100%'}} >
                    <option value="">Sub Category</option>
                    {subCategoryData?.map((item, i) => {
                      return (
                        <>
                          <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.subCategory}>{item.name}</option>
                        </>
                      ) })}
                  </select>
                </div>

</div>
                
              <div className="col-sm-3 col-xs-12 find">
                <input type="text" placeholder="search..." value={search} className="inputSearch" onChange={(e) => setsearch(e.target.value)} />
                <img width="25" height="25" src="https://img.icons8.com/glyph-neue/64/000000/search--v1.png" alt="search--v1" className="ctaonebutton1" onClick={() => onSubmitButtonClick(activeButton,search)}/>                
              </div>

              
              
          </div> */}
        </div>
      </section>


    </>

  );
};

export default GlobalJsx;
