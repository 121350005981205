import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import logo from "../../../assets/images/RimiblogoFinalTransparentfinal.png"
import searchheader from "../../../assets/images/searchheader.svg"
import offermenuicon from "../../../assets/images/offermenuicon.svg"
import asktheexpertmenuicon from "../../../assets/images/asktheexpertmenuicon.svg"
import offerblue from "../../../assets/homeimages/offerblue.svg"
import asktheexpertblue from "../../../assets/homeimages/asktheexpertblue.svg"

import productblue from "../../../assets/homeimages/productblue.svg"
import articalblue from "../../../assets/homeimages/articalblue.svg"
import bankingmenuicon from "../../../assets/images2/bankingmenuicon.svg"
import insurancemenuicon from "../../../assets/images2/insurancemenuicon.svg"
import mortgagemenuicon from "../../../assets/images2/mortgagemenuicon2.svg"
import investmentmenuicon from "../../../assets/images2/investmentmenuicon.svg"
import realestatemenuicon from "../../../assets/images2/realestatemenuicon.svg"
import productmenuicon from "../../../assets/images/productmenuicon.svg"
import articlemenuicon from "../../../assets/images/articlemenuicon.svg"
import hamburger from "../../../assets/images/hamburgermenu.jpg"
import search_header from "../../../assets/images2/search_header.png"
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import "../header/header.css";

const HeaderJsx = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const token = JSON.parse(sessionStorage.getItem('token'));
  const adminToken = sessionStorage.getItem('adminOldToken');

  const loginAs = sessionStorage.getItem('loginAs');

  const adminLoginData = sessionStorage.getItem('adminLoginData');


  const { pathname } = useLocation()
  const instituteExpert = localStorage.getItem('instituteExpert');




  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > 0) {
      document.body.classList.add('scrolled-down');
    } else {
      document.body.classList.remove('scrolled-down');
    }
    //setScrollY(currentScrollY); 

  };



  useEffect(() => {

    if (history.action === "PUSH") {
      const element = document.querySelector("#navbarSupportedContent");
      const classList = element.classList; // Check if the element has the "active" class.
      const hasActiveClass = classList.contains("show");
      if (hasActiveClass) {
        document.getElementById("mobilemenu").click()
      }

    }

  }, [pathname])
  useEffect(() => { window.addEventListener('scroll', handleScroll); return () => { window.removeEventListener('scroll', handleScroll); }; }, []);

  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      buttons: [
        'Continue',
        'Logout'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("loginAs");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("expertId");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("expertData");
        sessionStorage.removeItem("customerId");

        sessionStorage.removeItem("planName");
        sessionStorage.removeItem("legalName");
        sessionStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("description");
        sessionStorage.removeItem("flowName");
        sessionStorage.removeItem("flowType");
        sessionStorage.removeItem("subscriptionPlanId");
        sessionStorage.removeItem("planTypeID");
        sessionStorage.removeItem("planData");
        localStorage.removeItem("flowName");
        localStorage.removeItem("flowType");
        localStorage.removeItem("verificationStatus");
        localStorage.removeItem('VerifyInstituteID');
        localStorage.removeItem('AdminFlow');
        localStorage.removeItem('VerifyExpertID');
        sessionStorage.removeItem("comingFrom");


        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }
  const oldAdmin = sessionStorage.getItem("adminLoginData")
  const backAdmin = () => {
    swal({
      title: "Are you sure you want to Back ?",
      icon: "warning",
      buttons: [
        'Keep Logged in!',
        'Yes, Back!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {

        sessionStorage.setItem("token", JSON.stringify(adminToken));
        sessionStorage.removeItem("adminOldToken")

        // revert admin login dataa
        oldAdmin && sessionStorage.setItem("loginData", adminLoginData);
        oldAdmin && sessionStorage.removeItem("adminLoginData")
        //sessionStorage.removeItem("loginData")
        sessionStorage.removeItem("expertId")
        sessionStorage.removeItem("description")
        sessionStorage.removeItem("expertData")
        sessionStorage.removeItem("resumeOnBoard")
        sessionStorage.removeItem("loginAs");
        sessionStorage.removeItem("comingFrom");



        sessionStorage.removeItem("instituteId")
        localStorage.removeItem("slugSet")
        localStorage.removeItem("paymentData")

        localStorage.removeItem("verificationStatus")
        localStorage.removeItem("manageUnverifyExpert")
        localStorage.removeItem("VerifyInstituteID")
        localStorage.removeItem("VerifyExpertID")




        history.push("/admin/opsDashboardHome");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })
  }
  const [showMoreSearch, setShowMoreSearch] = useState(false);

  const backInstitute = () => {
    swal({
      title: "Are you sure you want to Back ?",
      icon: "warning",
      buttons: [
        'Keep Logged in!',
        'Yes, Back!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        let oldToken = localStorage.getItem("instituteExpertToken")
        sessionStorage.setItem("token", oldToken);
        history.push("/dashBoard");
        sessionStorage.removeItem("expertId");
        sessionStorage.removeItem("expertData");
        sessionStorage.removeItem("comingFromInstitute");
        sessionStorage.removeItem("comingFrom");

        sessionStorage.removeItem("description");

        localStorage.setItem('flowName', 'institute')
        localStorage.setItem('flowType', 'institute')
        localStorage.removeItem("instituteExpert");
        localStorage.removeItem("adInsExpert");

        localStorage.removeItem("instituteExpertToken");

        // window.location.reload()
        // sessionStorage.setItem("token", JSON.stringify(adminToken));
        // sessionStorage.removeItem("adminOldToken")
        // sessionStorage.removeItem("loginData")
        // sessionStorage.removeItem("expertId")
        // sessionStorage.removeItem("instituteId")
        // localStorage.removeItem("slugSet")
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })
  }

  const showSearchDiv = () => {
    setShowMoreSearch(true)
    window.scrollTo(0, 0);

  }


  const hideSearchDiv = () => {
    setShowMoreSearch(false)

  }


  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg sticky-top fixed-top custom nav" id="navbar_top">
          <div className="container">
            <a className="navbar-brand"><Link to={'/'} ><img src={logo} /></Link></a>
            <button className="navbar-toggler" id="mobilemenu" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">

                <img src={hamburger} />
              </span></button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mb-2 mb-lg-0 main-menu-nav">
                
                <li className={` nav-item ${window.location.href?.toLowerCase().includes('offers') ? 'active' : ""}`}>
                    <Link to={'/offers/all'} className="nav-link"><span className="menuicon"><img src={offermenuicon} className="imagehov1" /><img src={offerblue} className="imagehov2" /></span>{t("HEADER.MENU1")}</Link>
                  </li>
                {/* <li className={` nav-item ${window.location.href?.toLowerCase().includes('home') ? 'active' : ""}`}>
                  <Link to={'/'} className="nav-link">Home</Link>
                </li> */}
                {loginAs == "customer" ?
                  <li className={` nav-item ${window.location.href?.toLowerCase().includes('asktheexpert') ? 'active' : ""}`}>
                    <Link to={'/customerAskTheExpert'} className="nav-link"><span className="menuicon"><img src={asktheexpertmenuicon} className="imagehov1" /><img src={asktheexpertblue} className="imagehov2" /></span>{t("HEADER.MENU2")}</Link>
                  </li>
                  :
                  <li className={` nav-item ${window.location.href?.toLowerCase().includes('asktheexpert') ? 'active' : ""}`}>
                    <Link to={'/askTheExpert'} className="nav-link"><span className="menuicon"><img src={asktheexpertmenuicon} className="imagehov1" /><img src={asktheexpertblue} className="imagehov2" /></span>{t("HEADER.MENU2")}</Link>
                  </li>
                }


                <li className={` nav-item ${window.location.href?.toLowerCase().includes('products') ? 'active' : ""}`}>
                  <Link to={'/products'} className="nav-link"><span className="menuicon"><img src={productmenuicon} className="imagehov1" /><img src={productblue} className="imagehov2" /></span>   {t("HEADER.MENU3")}</Link>
                </li>
                <li className={` nav-item ${window.location.href?.toLowerCase().includes('blogs') ? 'active' : ""}`}>
                  <Link to={'/blogs'} className="nav-link"><span className="menuicon"><img src={articlemenuicon} className="imagehov1" /> <img src={articalblue} className="imagehov2" /></span>   {t("HEADER.MENU4")}</Link>
                  {/* <a className="nav-link" href="/#homeblog">Blogs</a> */}
                </li>
                {/* <li className={` nav-item ${window.location.href?.toLowerCase().includes('tools') ? 'active' : ""}`}>
                <Link to={'/tools'} className="nav-link">Tools</Link>
                </li> */}
                {/* <li className={` nav-item ${window.location.href?.toLowerCase().includes('search') ? 'active' : ""}`}>
                  <Link to={'/search'} className="nav-link">Search the Experts/Institutions</Link>
                </li> */}
                {/* <li className={` nav-item ${window.location.href?.toLowerCase().includes('searchexpert') ? 'active' : ""}`}>
                  <Link to={'/searchExpert'} className="nav-link">Search Experts</Link>
                </li> */}
                {/* <li className={` nav-item ${window.location.href?.toLowerCase().includes('searchinstitute') ? 'active' : ""}`}>
                  <Link to={'/searchInstitute'} className="nav-link">Search Institutions</Link>
                </li> */}
                {
                  loginAs ? loginAs == "expert" ?

                    <li className={` nav-item ${window.location.href?.toLowerCase().includes('searchExpert') ? 'active' : ""}`}>
                      <Link to={'/expertDashboardHome'} className="nav-link">{t("HEADER.MENU10")}</Link>
                    </li> :
                    loginAs == "customer" ?
                      <li className={` nav-item ${window.location.href?.toLowerCase().includes('searchExpert') ? 'active' : ""}`}>
                        <Link to={'/customerDashboard'} className="nav-link">{t("HEADER.MENU10")}</Link>
                      </li>
                      : <li className={` nav-item ${window.location.href?.toLowerCase().includes('searchExpert') ? 'active' : ""}`}>
                        <Link to={'/dashboard'} className="nav-link">{t("HEADER.MENU10")}</Link>
                      </li>
                    : null
                }


              </ul>

            </div>
            <div class="right-menu">
              <ul className="navbar-nav navbar-navright">
                <li className="seacheader"><button onClick={() => showMoreSearch ? hideSearchDiv() : showSearchDiv()}><img src={searchheader} /></button></li>
                <li className="headersignup"><a href="#">{t("HEADER.MENU5")}</a></li>
                {!token ? <li className={` nav-item headerlogin ${window.location.href?.toLowerCase().includes('login') ? 'active' : ""}`}>
                  <Link to={'/login'} className="nav-link">{t("HEADER.MENU6")}</Link>
                </li> :

                  instituteExpert ? <li className={` nav-item ${window.location.href?.toLowerCase().includes('logout') ? 'active' : ""}`}>
                    <a className="nav-link" onClick={backInstitute}>{t("HEADER.MENU7")}</a>
                  </li>
                    : adminToken ?
                      <li className={`headerlogin nav-item ${window.location.href?.toLowerCase().includes('logout') ? 'active' : ""}`}>
                        <a className="nav-link" onClick={backAdmin}>{t("HEADER.MENU8")}</a>
                      </li> :
                      <li className={`headerlogin nav-item ${window.location.href?.toLowerCase().includes('logout') ? 'active' : ""}`}>
                        <a className="nav-link" onClick={logout}>{t("HEADER.MENU9")}</a>
                      </li>
                }
              </ul>
            </div>
          </div>
        </nav>
        {
          showMoreSearch &&
          <section className={`globalOffProSearch pt-3 pb-3`}>
            <div className="container">
              <div class="customsearchblock">
                <input type="search" placeholder="" />
                <button class="headersearch">Search</button>
              </div>

            </div>
          </section>}
      </header>



    </>

  );
};

export default HeaderJsx;
