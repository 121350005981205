import React, { useEffect, useState } from 'react'
import { NEW_URL } from '../../../../constants/applicationConstants'; import dropbox from "../../../../assets/images/dropbox.svg";
import Sidebar from "../sidebar/sidebar.js";
import moment from 'moment';
import { Pagination } from '../../../common/pagination/pagination';
import insurancebg from "../../../../assets/images/insurancebg.png";
import RealEstatebg from "../../../../assets/images/RealEstatebg.png";
import mortgagebg from "../../../../assets/images/morgagegebg.png";
import bankingbg from "../../../../assets/images/bankingbg.png";
import investmentbg from "../../../../assets/images/investmentbg.png";

const CustomerQnaJsx = (props) => {
  const {
    data,onPaginationButtonClick,meta
  } = props;

  // const [backgroundColor, setBackgroundColor] = useState('#fff');
  // const colors = ['#B8FFB8'];

  // useEffect(() => {
  //   const hasRealEstate = data.some(list => list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'investment');
  //   if (hasRealEstate) {
  //     setBackgroundColor('#FFD0F8');
  //   } else {
  //     const randomColor = colors[Math.floor(Math.random() * colors.length)];
  //     setBackgroundColor(randomColor);
  //   }
  // }, [data, colors]); 
 

  return (
    <>
      <section class="alldashboard">

        <div className="container">
          <div className="row">

            <div className="col-lg-12 col-sm-12 mrg ">

              {
                data.length ? data?.map(list => {
                  return (
                  <div className="questioninnerin mb-3 backdeepblue"  style={{ background: list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'mortgage' ?  `url(${mortgagebg})`:list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'banking' ?  `url(${bankingbg})` : list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'real estate' ?  `url(${RealEstatebg})` : list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'insurance' ?  `url(${insurancebg})` : list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'investment' ?  `url(${investmentbg})`:"" ,backgroundSize: 'cover',minHeight:"302px",backgroundRepeat:"no-repeat"}} key={list.id}>
                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                      <div className="expertuser"><img src={!list?.expert?.imageUrl ? `https://ui-avatars.com/api/?name=${list?.expert?.firstName}+${list?.expert?.lastName}&rounded=true` : `${NEW_URL}/${list?.expert?.imageUrl}`} alt={''} /></div>
                      <div className="expName"><span>{`${list?.expert?.firstName} ${list?.expert?.lastName}`}</span>
                      <div className="questiondate">{moment(list?.askExpertQuestionUserResponse?.approvedAt).format('MM-DD-YYYY hh:mm A')}</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"  style={{ backgroundColor: list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'mortgage' ? '#2F596F' :list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'investment' ? '#86357A' : list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'banking' ? '#2281B8' :list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'real estate' ? '#00831D': "#FF4C00" }}><span>{list.askExpertQuestionUserResponse.parentInstitutionCategory}</span></div>
                    </div>
                     <h5 className="h5_style questionstext"><span>Question:</span> <b>{list.askExpertQuestionUserResponse.question}</b></h5>

                    <h5 className="h5_style questionstext"><span>Assigned to :</span></h5>

                  </div>
                  )
                } ) :

                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={''} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>You don't have any pending questions yet. </h5>
                    </div>
                  </div>
              }
              {data.length > 0 && <Pagination
                      limit={10}
                      totalCount={meta?.totalCount}
                      onPaginationButtonClick={onPaginationButtonClick}
                    />}
            </div>
          </div>
        </div>

      </section>
    </>

  );
};

export default CustomerQnaJsx;
