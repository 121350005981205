import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import realestateicon from "../../../../assets/images/realestateicon.svg";
import insurenceicon from "../../../../assets/images/insurenceicon.svg";
import mortgageicon from "../../../../assets/images/mortgageicon.svg";
import bankingicon from "../../../../assets/images/bankingicon.svg";
import investmenticon from "../../../../assets/images/investmenticon.svg";
import realestateicontwo from "../../../../assets/images/realestateicontwo.svg";
import insurenceicontwo from "../../../../assets/images/insurenceicontwo.svg";
import mortgageicontwo from "../../../../assets/images/mortgageicontwo.svg";
import bankingicontwo from "../../../../assets/images/bankingicontwo.svg";
import investmenticontwo from "../../../../assets/images/investmenticontwo.svg";
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import videoimage from "../../../../assets/images/videoimage.svg";
import videopop from "../../../../assets/images/videopop.svg";
import { Footer } from '../../../common/footer/footer';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import AOS from 'aos';
import Sidebar from "../sidebar/sidebar.js";
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import { NEW_URL } from '../../../../constants/applicationConstants';
import questionicon1 from "../../../../assets/images/questioniocn1.png";
import InputMask from 'react-input-mask';
import call from "../../../../assets/images2/call.png";
import fullname from "../../../../assets/images2/FullName.svg";
import email from "../../../../assets/images2/EmailAddress.svg";
import postal from "../../../../assets/images2/location.png";




export const getIconOne = (label) => {
    if (label === 'Real Estate')
        return realestateicon
    else if (label === 'Mortgage')
        return mortgageicon
    else if (label === 'Insurance')
        return insurenceicon
    else if (label === 'Banking')
        return bankingicon
    else if (label === 'Investment')
        return investmenticon
    else return ''
}

const CustomerAskTheExpertJsx = (props) => {
    const { t } = useTranslation();
    const {
        categoryData,
        onCategoryClick,
        categoryName,
        subCategoryData,
        onSubcategoryClick,
        subCategoryName,
        questionAsked,
        onTextAreaChange,
        onQuestionClick,
        onSubmitButtonClick,
        expertQuestions,
        onclickFocus,
        onInputChange,
        formData,
        formError,
        onTouchHandler,
        formSubmitButtonDisable
    } = props;

    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, [])
    const getMuskPattern = (name) =>
        name === 'phone' ? '(999) 999 9999' : 'a9a 9a9';

    const getIconTwo = (label) => {
        if (label === 'Real Estate')
            return realestateicontwo
        else if (label === 'Mortgage')
            return mortgageicontwo
        else if (label === 'Insurance')
            return insurenceicontwo
        else if (label === 'Banking')
            return bankingicontwo
        else if (label === 'Investment')
            return investmenticontwo
        else return ''
    }
    const Subcatslider = {

        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        vertical: true,
        verticalSwiping: true,
        dot: true,
    };
    return (
        <>
            <section class="entiredashboard maincustomerdash">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3 ">
                            <div className="dashboardleftpanel ">
                                <div className="dashboardlinkpop ">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                        <div className="SelectMainCategory col-lg-9 col-sm-9 dashboardcolor martop40 bgcolorcat opencustomer" id="ask" style={{ marginBottom: "10px 0" }}>
                                <div className="dashboardrightpannerinnerleft customask">
                                    <h2 className="h2_style font-weight-600">Ask the Experts</h2>
                                    <h5 className="h5_style">We understand that financial matters can be complex and overwhelming. That’s why we are dedicated to providing you with verified answers that truly make a difference. Ask questions and get tailored responses from certified experts at no cost & no obligations. .  </h5>
                                </div>
                            <div className="asktheexpertcategorysectioninner asktheexpertcategorysectioninnerline asktheexpertcategorysectioninnerlinenew mainglogsask">
                                <div className="catstart clearfix blogsasktheexpert customertASK" data-aostest="fade-up-right">
                                    <div className="categorytext">
                                        <h2>{t("ASKTHEEXPERT.SELECT")} <span>{t("ASKTHEEXPERT.CATEGORY")}</span></h2>
                                        <p className="catetext1 mt-3 mb-0">{t("ASKTHEEXPERT.TEXT1")}</p>
                                        <p className="catetext2 cutomerptext">{t("ASKTHEEXPERT.TEXT2")}</p>
                                    </div>
                                    <div className={`categoryonesetfirst wcustomerask ${categoryName && 'categoryoneset'}`}>
                                        <div className="choose-main" >
                                            {
                                                categoryData?.map(catDT => {
                                                    return <div className={`choose categoryonesettwo ${categoryName === catDT?.name ? 'active' : ''}`} onClick={() => onCategoryClick(catDT?.institutionCategoryId, catDT?.name)}>
                                                        <div className={`iconone ${categoryName === catDT?.name ? 'active' : ''}`} ><img src={getIconOne(catDT.name)} alt={''} /></div>
                                                        <div className="icontwo"><img src={getIconTwo(catDT.name)} alt={''} /></div>
                                                        {/* <h5>{catDT.name}</h5> */}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                {categoryName && <div className="catstart clearfix" data-aostest="fade-up-left">
                                    <div className={`categorytwosetfirsttest ${subCategoryName && 'categorytwoset'}`}>

                                        <div id="askts" onClick={(e) => onclickFocus("askQus")}>

                                            <div className="subcategoryinneraskexpert pt-3 pb-3">

                                                {
                                                    subCategoryData?.map(subCatDT => {
                                                        return <div id="askqw" className={`categoryonesettwo categoryonesetwidth ${subCategoryName === subCatDT?.name ? 'active' : ''}`} onClick={() => onSubcategoryClick(subCatDT.institutionCategoryId, subCatDT.name)}>
                                                            <div className="iconone"><img src={`${NEW_URL}/${subCatDT.icon}`}
                                                                alt={''} /></div>
                                                            <div className="icontwo"><img src={`${NEW_URL}/${subCatDT.icon}`}
                                                                alt={''} /></div>
                                                            <h5>{subCatDT.name}</h5>
                                                        </div>
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {subCategoryName && <div id="askQus">
                                    <div className="container">
                                        <div className="row catborderbottom maincustosection">
                                            <div className="col-lg-12 mb-3 d-none d-sm-block">
                                                <div className="catstart clearfix catinnercustomer" data-aostest="fade-up-bottom">
                                                    <div className="categoryfourset catcustomer">
                                                        <div className="categoryonesetone">
                                                            <div className="question-text textcenter">
                                                                <h2>{t("ASKTHEEXPERT.QUESTIONHEADING")}<span>{t("ASKTHEEXPERT.QUESTIONHEADING-1")}</span></h2>
                                                            </div>
                                                        </div>
                                                        {/* <AwesomeSlider>
                    {
                      expertQuestions.length > 0 ? expertQuestions.map(que => {
                        return <div onClick={() => onQuestionClick(que)}>{que.question}</div>

                      }) :

                        <div>No Question Found</div>
                    }
                  </AwesomeSlider> */}
                                                        <Slider {...Subcatslider}>

                                                            {expertQuestions.length > 0 ? (
                                                                expertQuestions.map(que => (
                                                                    <div className="main-question questionslider mainquestioncust">
                                                                        <div className="questionslider-text">
                                                                            <p>{que.question}</p> </div>
                                                                        <div className="questiontext-icon">
                                                                            <img src={questionicon1} key={que.id} onClick={() => onQuestionClick(que)} alt="" />
                                                                        </div>

                                                                    </div>

                                                                ))
                                                            ) : (
                                                                <div>No Question Found</div>
                                                            )}

                                                        </Slider>


                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-lg-12  mb-3 mobileblockscroll d-block d-sm-none maincatcustomer">
                                                <div className="catstart clearfix" data-aostest="fade-up-bottom">
                                                    <div className="categoryfourset catcustomer">
                                                        <div className="categoryonesetone">
                                                            <div className="question-text textcenter">
                                                                <h2>{t("ASKTHEEXPERT.QUESTIONHEADING")}<span>{t("ASKTHEEXPERT.QUESTIONHEADING-1")}</span></h2>
                                                            </div>
                                                        </div>
                                                        {expertQuestions.length > 0 ? (
                                                            expertQuestions.map(que => (
                                                                <div className="main-question questionslider mainquestioncust">
                                                                    <div className="questionslider-text">
                                                                        <p>{que.question}</p> </div>
                                                                    <div className="questiontext-icon">
                                                                        <img src={questionicon1} key={que.id} onClick={() => onQuestionClick(que)} alt="" />
                                                                    </div>

                                                                </div>

                                                            ))
                                                        ) : (
                                                            <div>No Question Found</div>
                                                        )}




                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-lg-12 mb-3 queastio-main questioncust">
                                                <div className="textarecs mt-5" data-aostest="fade-bottom">
                                                    <div className="question-text-right questioncustomer">
                                                        <h2>{t("ASKTHEEXPERT.QUESTIONHEADING1")}<span>{t("ASKTHEEXPERT.QUESTIONTEXT3")}</span></h2>
                                                        <p className="question-right-ptext">{t("ASKTHEEXPERT.QUESTIONTEXT4")}<span>{t("ASKTHEEXPERT.QUESTIONTEXTSUBTEXT4")}</span></p>
                                                        <p className="question-right-text-too">{t("ASKTHEEXPERT.QUESTIONTEXT5")}</p>
                                                    </div>
                                                    <div className="input-container form-group m-2 ">
                                                        <div className="input-question ">
                                                            <textarea className="input form-control textareaform maincustomertextarea" value={questionAsked} name="message" onChange={onTextAreaChange} placeholder=" "></textarea>
                                                            <div className="placeholder">Please type your question here... </div>
                                                        </div>
                                                        <div className="nextbtn">
                                                            <a className="nextbutton" href="#nextregister">Next</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <div className="register pb-4" id="nextregister">
                                    <div className="register-main">
                                        <div className="register-main-text customer-main-text">
                                            <h2>{t("ASKTHEEXPERT.REGISTERHEADING")}<span>{t("ASKTHEEXPERT.REGISTERHEADING1")}</span></h2>
                                            <h3>{t("ASKTHEEXPERT.SIGNUPTEXT")}</h3>
                                            <p>{t("ASKTHEEXPERT.SIGNUPTEXT1")}</p>
                                            <div className="regbtn text-center mt-5 mb-5">
                                                <a href="/customerRegistration" className="registernowbutton" >Register Now</a>
                                            </div>
                                        </div>
                                        <div className="mainexpforminner mt-5 register-fill">
                                            <div className="row" style={{ justifyContent: "center" }}>
                                                <div className="input-container form-group input-group-reg col-md-6 mb-5  ">
                                                    <span className="input-group-text"><img src={fullname} alt="" /></span>
                                                    <input
                                                        type="text"
                                                        name='name'
                                                        value={formData.name}
                                                        onChange={onInputChange}
                                                        onBlur={onTouchHandler}
                                                        className={
                                                            formError['name']
                                                                ? "input form-control  "
                                                                : "input form-control"
                                                        }
                                                        placeholder=" " />
                                                    <div className="placeholder">Full Name* </div>
                                                    <span className="text-danger">
                                                        {formError['name'] &&
                                                            (formError['name'] === "req"
                                                                ? 'Name is required'
                                                                : 'Enter a valid Name')}
                                                    </span>
                                                </div>
                                                <div className="input-container form-group input-group-reg col-md-6 mb-5">
                                                    <span className="input-group-text"><img src={email} alt="" /></span>
                                                    <input
                                                        type="email"
                                                        name='email'
                                                        value={formData.email}
                                                        onChange={onInputChange}
                                                        onBlur={onTouchHandler}
                                                        className={
                                                            formError['email']
                                                                ? "input form-control  "
                                                                : "input form-control"
                                                        }
                                                        placeholder=" " />
                                                    <div className="placeholder">Email Address* </div>
                                                    <span className="text-danger">
                                                        {formError['email'] &&
                                                            (formError['email'] === "req"
                                                                ? 'Email is required'
                                                                : 'Enter a valid Email')}
                                                    </span>
                                                </div>
                                                <div className="input-container form-group input-group-reg col-md-6 mb-5">
                                                    <span className="input-group-text"><img src={postal} alt="" /></span>
                                                    <InputMask mask={getMuskPattern('postalCode')} value={formData.postalCode} name="postalCode" onChange={onInputChange} onBlur={onTouchHandler}>
                                                        {(inputProps) => <input {...inputProps}
                                                            type="text"
                                                            className={
                                                                formError['postalCode']
                                                                    ? "input form-control  "
                                                                    : "input form-control"
                                                            }
                                                            placeholder=" "
                                                            disableUnderline />}
                                                    </InputMask>
                                                    <div className="placeholder">Postal Code* </div>
                                                    <span className="text-danger">
                                                        {formError['postalCode'] &&
                                                            (formError['postalCode'] === "req"
                                                                ? 'Postal code is required'
                                                                : 'Enter a valid Postal Code')}
                                                    </span>
                                                </div>
                                                <div className="input-container form-group input-group-reg mb-5 col-md-6">
                                                    <span className="input-group-text"><img src={call} alt="" /></span>
                                                    <InputMask mask={getMuskPattern('phone')} value={formData.phoneNumber} name="phoneNumber" onChange={onInputChange} onBlur={onTouchHandler}>
                                                        {(inputProps) => <input {...inputProps}
                                                            type="tel"
                                                            className={
                                                                "input form-control"
                                                            }
                                                            placeholder=" "
                                                            disableUnderline />}
                                                    </InputMask>
                                                    <div className="placeholder">Phone Number </div>
                                                    <span className="text-danger">
                                                        {formError['phoneNumber'] &&
                                                            (formError['phoneNumber'] === "req"
                                                                ? ''
                                                                : 'Enter a valid Phone Number')}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="proceed-main-btn   mb-3 text-end ">
                                                <button className="proceedbtn" disabled={formSubmitButtonDisable} onClick={onSubmitButtonClick}>Proceed</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-9 col-sm-9">
                            <div className="dashboardrightpanel martop40 mb-5">
                                <div className="dashboardrightpannerinner">
                                    <div className="dashboardrightpannerinnerleft">
                                        <h2 className="h2_style font-weight-600">Ask the Experts</h2>
                                        <h5 className="h5_style">We understand that financial matters can be complex and overwhelming.
                                        That’s why we are dedicated to providing you with verified answers that truly make a difference.
Ask questions and get tailored responses from certified experts at no cost & no obligations. </h5>

                                    </div>
                                    <div className="dashboardrightpannerinnerright">
                                        <div className="signout">
                                            <a href="#logout">
                                                <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                                            </a>
                                        </div>
                                        <div id="logout" class="modal-window">
                                            <div>
                                                <a title="Close" class="modal-close">Close</a>
                                                <div className="modal-header">

                                                </div><div className="modal-body mb-4">
                                                    <div className="modal-body-inner">
                                                        <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="ctaonebutton"> Save</button>
                                                    <button type="button" className="ctatwobutton"> Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className="asktheexpertcategorysection" id="ask">

                                    <div className="asktheexpertcategorysectioninner asktheexpertcategorysectioninnerline">

                                        <div className="catstart clearfix" data-aostest="fade-up-right">
                                            <div className={`categoryonesetfirst ${categoryName && 'categoryoneset'}`}>
                                                <div className="disgrid grid-3">
                                                    <div className="categoryonesetone" >
                                                        <p>{t("ASKTHEEXPERT.SELECT")}</p>
                                                        <h4>{t("ASKTHEEXPERT.CATEGORY")}</h4>
                                                        <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.TEXT1")}</b> {t("ASKTHEEXPERT.TEXT2")}</p>
                                                    </div>
                                                    {
                                                        categoryData?.map(catDT => {
                                                            return <div className={`categoryonesettwo ${categoryName === catDT?.name ? 'active' : ''}`} onClick={() => onCategoryClick(catDT?.institutionCategoryId, catDT?.name)}>
                                                                <div className={`iconone ${categoryName === catDT?.name ? 'active' : ''}`} ><img src={getIconOne(catDT.name)} alt={''} /></div>
                                                                <div className="icontwo"><img src={getIconTwo(catDT.name)} alt={''} /></div>
                                                                <h5>{catDT.name}</h5>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {categoryName && <div className="catstart clearfix" data-aostest="fade-up-left">
                                            <div className={`categorytwosetfirst ${subCategoryName && 'categorytwoset'}`}>

                                                <div className="disgrid grid-4" id="askts" onClick={(e) => onclickFocus("askQus")}>
                                                    <div className="categoryonesetone anotherexp">
                                                        <p>{t("ASKTHEEXPERT.SELECT2")}</p>
                                                        <h4>{t("ASKTHEEXPERT.CATEGORY2")} </h4>
                                                        <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.TEXT3")}</b> {t("ASKTHEEXPERT.TEXT4")}</p>
                                                    </div>
                                                    {
                                                        subCategoryData?.map(subCatDT => {
                                                            return <div id="askqw" className={`categoryonesettwo categoryonesetwidth ${subCategoryName === subCatDT?.name ? 'active' : ''}`} onClick={() => onSubcategoryClick(subCatDT.institutionCategoryId, subCatDT.name)}>
                                                                <div className="iconone"><img src={`/${(subCatDT?.name).replace(/\s/g, '')}.png`} alt={''} /></div>
                                                                <div className="icontwo"><img src={`/${(subCatDT?.name).replace(/\s/g, '')}.png`} alt={''} /></div>
                                                                <h5>{subCatDT.name}</h5>
                                                            </div>
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {subCategoryName && <div id="askQus">
                                            <div className="catstart clearfix" data-aostest="fade-up-up">
                                                <div className="categorythreeset">
                                                    <div className="categoryonesetone">

                                                        <h4>{t("ASKTHEEXPERT.QUESTIONHEADING")}</h4>
                                                        <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.QUESTIONTEXT1")} </b>
                                                            {t("ASKTHEEXPERT.QUESTIONTEXT2")}</p>

                                                    </div>

                                                    <AwesomeSlider>
                                                        {
                                                            expertQuestions.length > 0 ? expertQuestions.map(que => {
                                                                return <div onClick={() => onQuestionClick(que)}>{que.question}</div>

                                                            }) :

                                                                <div>No Question Found</div>
                                                        }
                                                    </AwesomeSlider>

                                                </div>
                                            </div>
                                            <div className="catstart clearfix" data-aostest="fade-up-bottom">
                                                <div className="categoryfourset">
                                                    <div className="categoryonesetone">
                                                        <p>{t("ASKTHEEXPERT.QUESTIONHEADING1")} </p>
                                                        <h4>{t("ASKTHEEXPERT.QUESTIONTEXT3")} </h4>
                                                        <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.QUESTIONTEXT4")}</b> {t("ASKTHEEXPERT.QUESTIONTEXT5")}</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="textarecs mt-5" data-aostest="fade-bottom">
                                                <div className="input-container form-group">
                                                    <textarea className="input form-control textareaform" value={questionAsked} name="message" onChange={onTextAreaChange} placeholder=" "></textarea>
                                                    <div className="placeholder">This is what you’ll be asking the experts.... </div>
                                                </div>
                                            </div>
                                            <div className="mainexpform mb-5 mt-5" data-aostest="fade-up-right">
                                                <h4 className="text-center">Sit tight! Our experts know exactly what you need. </h4>
                                                <h5 className="text-center">Just enter the details below. Get precise, valuable & objective responses.  </h5>
                                                <div className="text-center">
                                                    <Recaptcha
                                        key={Math.random()}
                                        sitekey="6LdcBSEgAAAAAIs7aSdegby02xHlo_C8BNH6z-tX"
                                        onChange={onChange}
                                        />
                                                </div>
                                                <div className="text-center">
                                                    <button type="button" disabled={!questionAsked} className="ctaonebutton widthhalf mt-3" onClick={onSubmitButtonClick}>Submit</button>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>

                                </section>

                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            <Footer />

        </>

    );
};

export default CustomerAskTheExpertJsx;