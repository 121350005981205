import React from 'react'
import { useEffect, useState } from 'react';
import { Footer } from '../../common/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NEW_URL } from '../../../constants/applicationConstants';
import dropbox from "../../../assets/images/dropbox.svg";
import Insurancebanner from "../../../assets/images/Insurancebanner.png";
import { useLocation } from 'react-router-dom';
import "../insurance/insurance.css";
import "../insurance/insuranceresponsive.css";
import borderbottom from "../../../assets/insuranceimges/borderbottom.png";
import iconaskbtn from "../../../assets/insuranceimges/iconaskbtn.svg";
import RealEtext from "../../../assets/insuranceimges/RealEtext.png";
import toprealEttextline from "../../../assets/insuranceimges/toprealEttextline.png";
import toprealE1 from "../../../assets/insuranceimges/toprealE1.png";
import toprealE2 from "../../../assets/insuranceimges/toprealE2.png";
import toprealE3 from "../../../assets/insuranceimges/toprealE3.png";
import toprealE4 from "../../../assets/insuranceimges/toprealE4.png";
import expertuiserborder from "../../../assets/insuranceimges/expertuiserborder.png";

const InsuranceJsx = (props) => {
  const { t } = useTranslation();
  const {
    offerData,
    instituteData,
    adData,
    expertData,
    blogData,
    userData,
    onTouchHandler,
    onInputChangeHandler,
    onOfferSearchButtonClick,
    onAdSearchButtonClick,
    onExpertClick,
    onInstituteClick, subCategoryData
  } = props;
  const { hash } = useLocation()
  useEffect(() => {
    AOS.init();
    if (hash) {
      document.getElementById(hash.replace('#', '')).scrollIntoView()
    } else {
      //window.scrollTo(0, 0);
    }

  }, [])

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '20px',
    //  initialSlide:2    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var expmorgage = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '20px',
    //  initialSlide:2    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState(null);

  const [visibleBlogs, setVisibleBlogs] = useState(4);
  const handleViewAll = () => {
    setVisibleBlogs(blogData?.body?.length);
  };
  return (
    <>
    <section className="insurancenewui">
      <section className=" herosectionoffer categorysectiondis   insuranceBanner insurance">
        <div className="container makethecontainer">
          <div className="row">
            <div className="col-lg-12 col-md-6 col-12 realEinner">
              <div className="rimibmentfont borderbottom ">
                <h2>{t("INSURANCECHILD.TEXT1")}</h2>
                <img className="borderimg" src={borderbottom} alt="" />
                <p><span className="spantextcomm"> {t("INSURANCECHILD.TEXT2")}  </span> {t("INSURANCECHILD.TEXTONE2")}</p>
                <h6 className="h6_style "><b className="realEbold">  </b>{t("INSURANCECHILD.TEXT3")} <b className="realEbold"> {t("INSURANCECHILD.TEXTONE3")} </b>{t("INSURANCECHILD.TEXTTWO3")}</h6>
                <p className="rimibmentsubtext">{t("INSURANCECHILD.TEXT5")}<span>  {t("INSURANCECHILD.TEXTONE5")}</span> </p>
                <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                  <div className="realEbuttonmain realEhero askbuttoncomm">
                    <p className="realEbutton"> {t("INSURANCECHILD.TEXT25")}</p>
                    <img className="imgsized" src={iconaskbtn} alt="" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="homepageproductoffer  institumainsection  insurancetwo" id="homerealestate">
        <div className="container">
          <div className="letest-inner resclass">
            <h2> {t("INSURANCECHILD.TEXT6")}</h2>
            <h6> {t("INSURANCECHILD.TEXT7")}
              <br /> {t("INSURANCECHILD.TEXTTWO8")}</h6>
          </div>
          <div className="institumain displaynone">
            <div className="latestbutton margintoptop">
              <button href="#" className="insuranceviewallbuttoncomm" style={{ textDecoration: "none" }}> {t("INSURANCECHILD.TEXT24")}</button>
            </div>
          </div>

          <div className="row mt-5  offersss ">
            {offerData?.length > 0 ? (
              <Slider {...settings}>
                {offerData.map((offer) => {
                  return (
                    <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 mb-5">
                      <div className="offer-insurence-inner">
                        <div className="offer-image">
                          <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />
                        </div>
                        <div className="offer-name mt-3 mb-3 disfontsmall">
                          {offer?.offerName}
                        </div>
                        <div className="posbuttontf d-flex">
                          <a
                            href="{offer?.externalUrl}"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-center insuranceapplynowbuttoncomm"
                          >
                            {t("INSURANCECHILD.TEXT29")}
                          </a>
                          <Link
                            to={{
                              pathname: "/products",
                              state: { data: offer, frompage: "homerealestate", pagePath: "/realEstate" },
                            }}
                            className="insuranceviewdetailsbuttoncomm text-center ctanewtwobutton"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {t("INSURANCECHILD.TEXT30")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">{t("INSURANCECHILD.TEXT31")}</h5>
                </div>
              </div>
            )}
            <div className="latestbutton">
            <Link to={'/offers/1'} className="investmentviewallbuttoncomm" style={{ textDecoration: "none" }}>{t("INSURANCECHILD.TEXT24")}</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="homepageproductoffer insurancethree">
        <div className="container">
          <div className="TopRealEstateProducts-inner">
            <h2 className="investthreesubtext">{t("INSURANCECHILD.TEXT9")}</h2>
            <h6>{t("INSURANCECHILD.TEXT10")}
              <br /><span>{t("INSURANCECHILD.TEXT11")}</span></h6>
          </div>
          <div className="row mt-5 offersss">
            {offerData?.length > 0 ? (
              <Slider {...settings}>
                {offerData.map((offer) => {
                  return (
                    <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 mb-5">
                      <div className="offer-insurence-inner">
                        <div className="offer-image">
                          <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />

                        </div>
                        <div className="offer-name mt-3 mb-3 disfontsmall">
                          {offer?.offerName}
                        </div>
                        <div className="posbuttontf d-flex">
                          <a
                            href={offer?.externalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="insuranceapplynowbuttoncomm text-center"
                          >
                            {t("INSURANCECHILD.TEXT29")}
                          </a>
                          <Link
                            to={{
                              pathname: "/detailedOffer",
                              state: { data: offer, frompage: "homerealestate", pagePath: "/realEstate" },
                            }}
                            className="insuranceviewdetailsbuttoncomm text-center ctanewtwobutton"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {t("INSURANCECHILD.TEXT30")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">{t("INSURANCECHILD.TEXT31")}</h5>
                </div>
              </div>
            )}
            <div className="latestbutton">
              <button href="#" className="viewallbuttoncomm" style={{ textDecoration: "none" }}>{t("INSURANCECHILD.TEXT24")}</button>
            </div>
          </div>
        </div>
      </section>
      <section className="homepageproductoffer insurancefour">
        <div className="container">
          <h2 className="text-center letestadsheading">{t("INSURANCECHILD.TEXT12")}</h2>
          <h6 className="h6_style text-center realeheading" style={{ marginBottom: "0px" }}>{t("INSURANCECHILD.TEXT13")}</h6>
          <h6 className="h6_style text-center realeheading">{t("INSURANCECHILD.TEXT14")}</h6>
          <div className="row  offersss">
            {adData?.length > 0 ? (
              <Slider {...settings}>
                {adData.map((data, offer) => {
                  return (
                    <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 mb-5">
                      <div className="offer-insurence-inner">
                        <div className="offer-image">
                          <img src={`${NEW_URL}/${data?.imageUrl}`} alt={''} />
                        </div>
                        <div className="offer-name mt-3 mb-3 disfontsmall">
                          {data?.subInstitutionCategory?.name}
                        </div>
                        <div className="posbuttontf d-flex">
                          <a
                            href={offer?.externalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="insuranceapplynowbuttoncomm text-center"
                          >
                            {t("INSURANCECHILD.TEXT29")}
                          </a>
                          <Link
                            to={{
                              pathname: "/detailedOffer",
                              state: { data: offer, frompage: "homerealestate", pagePath: "/realEstate" },
                            }}
                            className="insuranceviewdetailsbuttoncomm text-center ctanewtwobutton"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {t("INSURANCECHILD.TEXT30")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">{t("INSURANCECHILD.TEXT31")}</h5>
                </div>
              </div>
            )}
            <div className="latestbutton">
              <button href="#" className="viewallbuttoncomm" style={{ textDecoration: "none" }}>{t("INSURANCECHILD.TEXT24")}</button>
            </div>
          </div>
        </div>
      </section>
      <section className="morgaged insuranced insurancefive">
        <div className="container">
          <div className="row toprealEflexflow" >
            <div className="col-lg-5 col-12 p-0 ">
              <div className="toprealEmain">
                <div className="row ">
                  {instituteData?.length > 0 ? (
                    instituteData.map((institute, index) => (
                      <div className="col-lg-6 col-sm-4 " key={index}>
                        <div
                          className="colinstititeinner"
                          onClick={() => onInstituteClick(institute)}
                        >
                          <div className="colinstititeinnerimage text-center">
                            <img
                              src={`${NEW_URL}/${institute?.imageUrl}`}
                              alt={institute?.name || "Institute Image"}
                            />
                          </div>
                          <div className="colinstititeinnername text-center institute-text">
                            {institute?.name}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="dropdefault text-center">
                      <div className="dropdefaultimage">
                        <img src={dropbox} alt="No records" />
                      </div>
                      <div className="dropdefaulttext">
                        <h5 className="opacity0">No Record Found !!</h5>
                      </div>
                    </div>
                  )}


                </div>

              </div>
              <div className="showallbtn text-center mt-3">
                <a
                  href="/searchInstitute"
                  className="toprealEbutton"
                  style={{ textDecoration: "none" }}
                >
                  Show All
  </a>
              </div>

            </div>
            <div className="col-lg-7 col-12 p-0">
              <div className="toprealErighttext">
                <h2>{t("INSURANCECHILD.TEXT15")} <span><img className="toprealEtext" src={RealEtext} alt="" />{t("INSURANCECHILD.TEXTONE15")}</span>  <span className="canada">{t("INSURANCECHILD.TEXTTWO15")}</span></h2>
                <img className="toprealEborder" src={toprealEttextline} alt="" />
                <p><span className="topsubtext">{t("INSURANCECHILD.TEXT16")} </span>{t("INSURANCECHILD.TEXTONE16")} <span className="topsubtext"> {t("INSURANCECHILD.TEXTTWO16")} </span> {t("INSURANCECHILD.TEXTTHREE16")}<br /></p>
                <span className="topsubtextfive">{t("INSURANCECHILD.TEXT17")}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="homepageproductoffer bestdeals Experts   insurancesix">
        <div className="container-fluid">
          <h2 className="text-center expertheading">{t("INSURANCECHILD.TEXT18")}</h2>
          <h5 className="text-center  h5_style expertsub1">{t("INSURANCECHILD.TEXTONE18")}<br />
          </h5>
          <p className="expertsub2">{t("INSURANCECHILD.TEXTTWO18")}</p>

          <div className=" p bestdealsbox bestexperts">
            {expertData?.length > 0 ?
              <Slider {...expmorgage}>
                {expertData?.map(data => {
                  return (
                    <div class="mt-5">
                      <div className="Expertsitmes" onClick={() => onExpertClick(data?.slugUrl)}>
                        <div className="Expertstop"><img src={`${NEW_URL}/${data?.imageUrl}`} alt={' '} />
                        </div>
                        <div className="Expertsbottom  text-center">
                          <div className="Expertstext"><h5>{`${data.firstName} ${data.lastName}`}</h5> <img src={expertuiserborder} alt="" /></div>
                          <p>{data?.description}</p>
                          <div className="Expertsbutton">
                            <div className="Expertsbuttoni">
                              <a href="" className="insurancemessagemebuttoncomm">{t("INSURANCECHILD.TEXT27")}</a>
                              <a href="" className="insurancemyadsbuttoncomm">{t("INSURANCECHILD.TEXT28")}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
              :
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt={' '} />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">{t("INSURANCECHILD.TEXT31")} </h5>
                </div>
              </div>

            }
            <div className="latestbutton  marginbottom">
              <a href="/searchExpert" className="viewallbuttoncomm" style={{ textDecoration: "none" }}>{t("INSURANCECHILD.TEXT26")}</a>
            </div>
          </div>

        </div>
      </section>
      <section className="homepageproductoffer   insuranceseven responsived">
        <div className="container-fluid">
          <div className="realEquestion">
            <div className="realEquestiontext ">
              <h4>{t("INSURANCECHILD.TEXT19")}</h4>
              <h2 className="resstext"><span>{t("INSURANCECHILD.TEXTTWO19")}</span> {t("INSURANCECHILD.TEXTTHREE19")}<span>{t("INSURANCECHILD.TEXTFOUR19")}</span>{t("INSURANCECHILD.TEXTFIVE19")}</h2>
              <h3 className=""
              >{t("INSURANCECHILD.TEXT20")}</h3>
              <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                <div className="realEbuttonmain realEmargin askbuttoncomm">
                  <p className="realEbutton">  {t("INSURANCECHILD.TEXT25")}</p>
                  <img src={iconaskbtn} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="homepageproductoffer blogdetailsexpertppv  realEblogbg insuranceeight">
        <div className="container-fluid">
          <div className="realEbloginner resfont">
            <h2 className="text-center"
            >{t("INSURANCECHILD.TEXT21")}</h2>
            <p className="text-center realEblogtext">{t("INSURANCECHILD.TEXT22")}
            </p>
            <p> {t("INSURANCECHILD.TEXT23")}<br />{t("INSURANCECHILD.TEXTONE23")}</p>
          </div>
          <div className="row mt-5 realEbloglog">
            {
              blogData?.body?.length > 0 ?
                blogData?.body?.slice(0, visibleBlogs).map(blog => {
                  return <div className="col-lg-6 col-md-6 col-sm-6 minheightmorgage">
                    <a onClick={() => { setBlog(blog); setOpenModal(true) }} >
                      <div className="bloginner mb-3 blogsmain text-center">
                        <div className="bloginnerimage "><img className="blogs-user-image" src={blog?.image} alt={' '} /></div>
                        <div className="bloginnertag  displaynone">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                        <div className="bloginnertext ">{blog?.title}</div>
                        <div className="bloginnerdate ">{blog?.short_content}</div>
                      </div>

                    </a>

                  </div>
                }) :

                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={' '} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5>You don't have any Blogs yet. </h5>
                  </div>
                </div>
            }

            {
              blogData?.body?.length > visibleBlogs &&
              <div className="latestbutton  minuspace">
                <button href="#" className="viewallbuttoncomm" style={{ textDecoration: "none" }} onClick={handleViewAll}>
                  {t("INSURANCECHILD.TEXT24")}
                </button>
              </div>
            }
          </div>
        </div>
      </section>
      <section className="homecustom">
        <Footer />

        {
          openModal &&
          <div className="modalspecial blog-modal">
            <div>
              <div className="mybtn">
                <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                  preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                    fill="#ffffff" stroke="none">
                    <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                  </g>
                </svg></button>
              </div>
              <div className="modal-header text-center">
                <h3>{blog?.title}</h3>
              </div>
              <div className="modal-body text-center">
                <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                </div>
                <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
              </div>
            </div>
          </div>

        }


      </section></section>
    </>

  );
};

export default InsuranceJsx;