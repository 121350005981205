import React from 'react'
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import { Footer } from '../../common/footer/footer';
import rimib1 from "../../../assets/offersimage/RealEstate.svg";
import rimib2 from "../../../assets/offersimage/Investment.svg";
import rimib3 from "../../../assets/offersimage/Mortgage.svg";
import rimib4 from "../../../assets/offersimage/Insurance.svg";
import rimib5 from "../../../assets/offersimage/Banking.svg";
import Vector1 from "../../../assets/offersimage/Vector1.svg";
import Vector2 from "../../../assets/offersimage/Vector2.svg";
import selectall from "../../../assets/offersimage/Select_all.svg";
import offerbanner from "../../../assets/offersimage/offers_page_banner.svg";
import offerbannermobile from "../../../assets/images/Top_Offer_Mobile_banner.JPG";
import Slider from "react-slick";
import 'react-awesome-slider/dist/styles.css';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { uploadURL, NEW_URL } from '../../../constants/applicationConstants';
import { getProperWebsite } from '../../../shared/helper';
import "../offers/offers.css";
import "../offers/offerresponsive.css";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const OffersJsx = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation()

  const {
    categoryData,
    subCategoryData,
    onSubmitButtonClick,
    formData,
    onInputChange,
    formError,
    investmentOfferData,
    allOffersData,
    mortgageOfferData,
    insuranceOfferData,
    bankingOfferData,
    realestateOfferData,
    topOffers, getAllFeauturedOffersData, selectCategory, selectSubCategory
  } = props;
  useEffect(() => {
    getAllFeauturedOffersData()
  }, [pathname])
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };
  var settingstwo = {
    dots: true,
    arrows: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  const onOfferClick = (offer) => {
    if (offer?.externalUrl) {
      window.open(getProperWebsite(offer.externalUrl), '_blank');
    }
  }

  return (
    <>
      <section className="newofferdesign">
        <section className="herosectionoffer">

          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-sm-7">
                <div className="offerherobannertext">
                  <h2><span>{t("OFFERE.TEXT1")}</span></h2>
                  <h2>{t("OFFERE.TEXT2")}</h2>
                  <h5>{t("OFFERE.TEXT3")}</h5>
                </div>
              </div>
              <div className="col-lg-5 col-sm-5"> <div className="offerbannerslider">
                <div className="vectorone"><img src={Vector1} /></div>
                <div className="vectortwo"><img src={Vector2} /></div>
                <Slider {...settingstwo}>
                  {
                    topOffers?.map(offer => {
                      return <div className="sliderContent">

                        <a href={offer.externalUrl ?? '#'} target="_blank">
                          <img
                            className="d-block w-100"
                            src={`${NEW_URL}/${offer.imageUrl}`}
                            alt=" "
                          />
                        </a>
                        {/* <h5>{offer.offerName}</h5> */}
                      </div>
                    })
                  }
                </Slider>
              </div></div>
            </div>

          </div>
        </section>
        <section className="offersearch">
          <div className="container">
            <div class="customsearchblock">
              <input type="search" placeholder="" />
              <button class="headersearch">{t("OFFERE.TEXT4")}</button>
            </div>
            <h2>{t("OFFERE.TEXT5")}</h2>
            <h4>{t("OFFERE.TEXT6")}</h4>
          </div>
        </section>
        {/* <section className="newserachfield">
        <div className="container">
          <div className="myexpertformarea searcharea martop40">
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="category"
                onChange={onInputChange}
                // onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {categoryData?.map((item) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Category</div>
            </div>
            <div className="input-container form-group">
            <select
                className="input form-control form-select"
                placeholder=" "
                name="subCategory"
                onChange={onInputChange}
                value={formData.subCategory}

                // onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {subCategoryData?.map((item, i) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.subCategory}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Sub Category</div>
            </div>
            <div className="input-container form-group">
              <button type="submit" className="ctaonebutton"  onClick={() => onSubmitButtonClick()}>Search Offers</button>
            </div>
          </div>
        </div>
      </section> */}


        {/* Real estate offers */}

        <section className="limitedoffer mt-5">
          <div className="container">
            <section className="mainofferdetails">
              <h2>{t("OFFERE.TEXT7")}</h2>
              <h5>{t("OFFERE.TEXT8")}</h5>
              <h5><b>{t("OFFERE.TEXT9")}</b></h5>
              {/* <div className="filtersectionglobal">
                <div className="selectallcheckbox">
                  <div class="form-checkselect">

                    <input class="form-check-inputselect" type="checkbox" value="" id="Checkall" />
                    <span className="maincheckboxicon"><img src={selectall} /></span><label class="form-check-label mainchecklabel" for="Checkme4">Select All</label>
                  </div>
                </div>
                <div className="selectallinnercheckbox">
                  <ul>
                    <li className="selectinner Real Estate" disabled>
                      <div class="dropdown">
                        <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                          <input class="form-check-input" type="checkbox" value="" id="Checkmerealestate" />
                          <span className="maincheckboxicon"><img src={rimib1} /></span><label class="form-check-label mainchecklabel" for="Checkme4">Real Estate</label>

                        </div>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme1" />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span> <label class="form-check-label" for="Checkme1">Check me</label>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme2" checked />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                                <label class="form-check-label" for="Checkme2">Check me</label>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme3" />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                                <label class="form-check-label" for="Checkme3">Check me</label>
                              </div>
                            </a>
                          </li>


                        </ul>
                      </div>


                    </li>
                    <li className="selectinner Investment">
                      <div class="dropdown">
                        <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                          <input class="form-check-input" type="checkbox" value="" id="Checkmeinvestment" />
                          <span className="maincheckboxicon"><img src={rimib2} /></span><label class="form-check-label mainchecklabel" for="Checkme4">Investment</label>

                        </div>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme1" />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span> <label class="form-check-label" for="Checkme1">Check me</label>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme2" checked />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                                <label class="form-check-label" for="Checkme2">Check me</label>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme3" />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                                <label class="form-check-label" for="Checkme3">Check me</label>
                              </div>
                            </a>
                          </li>


                        </ul>
                      </div>


                    </li>
                    <li className="selectinner Mortgage">
                      <div class="dropdown">
                        <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                          <input class="form-check-input" type="checkbox" value="" id="Checkmemortgage" />
                          <span className="maincheckboxicon"><img src={rimib3} /></span><label class="form-check-label mainchecklabel" for="Checkme4">Mortgage</label>

                        </div>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme1" />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span> <label class="form-check-label" for="Checkme1">Check me</label>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme2" checked />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                                <label class="form-check-label" for="Checkme2">Check me</label>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme3" />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                                <label class="form-check-label" for="Checkme3">Check me</label>
                              </div>
                            </a>
                          </li>


                        </ul>
                      </div>


                    </li>
                    <li className="selectinner Insurance">
                      <div class="dropdown">
                        <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                          <input class="form-check-input" type="checkbox" value="" id="Checkmeinsurance" />
                          <span className="maincheckboxicon"><img src={rimib4} /></span><label class="form-check-label mainchecklabel" for="Checkme4">Insurance</label>

                        </div>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme1" />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span> <label class="form-check-label" for="Checkme1">Check me</label>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme2" checked />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                                <label class="form-check-label" for="Checkme2">Check me</label>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme3" />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                                <label class="form-check-label" for="Checkme3">Check me</label>
                              </div>
                            </a>
                          </li>


                        </ul>
                      </div>


                    </li>
                    <li className="selectinner Banking">
                      <div class="dropdown">
                        <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                          <input class="form-check-input" type="checkbox" value="" id="Checkmebanking" />
                          <span className="maincheckboxicon"><img src={rimib5} /></span><label class="form-check-label mainchecklabel" for="Checkme4">Banking</label>

                        </div>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme1" />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span> <label class="form-check-label" for="Checkme1">Check me</label>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme2" checked />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                                <label class="form-check-label" for="Checkme2">Check me</label>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Checkme3" />
                                <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span>
                                <label class="form-check-label" for="Checkme3">Check me</label>
                              </div>
                            </a>
                          </li>


                        </ul>
                      </div>


                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="filtersectionglobal">
                <div className="selectallcheckbox">
                  <div class="form-checkselect">

                    <input class="form-check-inputselect" type="checkbox" value="" id="Checkall" />
                    <span className="maincheckboxicon"><img src={selectall} /></span><label class="form-check-label mainchecklabel" for="Checkme4">{t("OFFERE.TEXT10")}</label>
                  </div>
                </div>
                <div className="selectallinnercheckbox">
                  <ul>

                    {categoryData?.map((item) => {
                      return (
                        <>
                          <li className={`selectinner ${item.name}`} >
                            <div class="dropdown">

                              <div>
                                <input class="form-check-input" type="checkbox" value="" id="Checkmerealestate" onChange={() => selectCategory(item)} />
                              </div>
                              <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                                <span className="maincheckboxicon"><img src={rimib1} /></span><label class="form-check-label mainchecklabel" for="Checkme4">{item.name}</label>

                              </div>
                              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                                {item?.subInstitutionCategories?.map((subItem) => {
                                  return (
                                    <>
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="Checkme1" onChange={() => selectSubCategory(subItem)} />
                                            <span className="subcatimage"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/d3ee9916-6251-42ea-999f-790b3de84f77.png" /></span> <label class="form-check-label" for="Checkme1">{subItem?.name}{subItem?.institutionCategoryId}</label>
                                          </div>
                                        </a>
                                      </li>
                                    </>
                                  )
                                })}


                              </ul>
                            </div>
                          </li>
                        </>
                      )
                    })}




                  </ul>
                </div>
              </div>
            </section>
            <section className="sortingdiv">
          <div className="container">
            <span className="shortby">Sort By</span>
            <ul>
              <li className="active"><a href=""> Newest</a></li>
              <li><a href=""> Expiring Shortly</a></li>
            </ul>
          </div>
        </section>
            <div className="limitedofferinner">

              <div className="offerslider martop40 mb-5">
                <div className="row">

                  {
                    allOffersData?.map(offer => {
                      return <div className="col-lg-6 col-sm-6">
                        <div className={`sliderContent ${offer?.parentInstitutionCategory}`}>
                          <img
                            className="d-block w-100"
                            src={`${NEW_URL}/${offer.imageUrl}`}
                            alt=" "
                          />

                          <div className="descriptionppvpop">
                            <div className="d-flex ppvcatds">


                              <div>{offer?.parentInstitutionCategory}</div><div>  {offer?.subInstitutionCategory?.name}</div>

                            </div>
                            <div className="offerdspop">
                              <div className="ppvcat">
                                <h3>{t("OFFERE.TEXT11")}</h3>
                                <h5>{offer?.offerName}</h5>
                              </div>


                              {/* <div className="ppvdate">
                            <h3>Expires On</h3>
                            {getProperData(offer?.validTo)}
                          </div> */}
                            </div>
                            <h3>{t("OFFERE.TEXT12")}</h3>
                            <h5> {offer?.description}</h5>

                          </div>

                          <button onClick={() => onOfferClick(offer)} className="ctaonebutton widthhalf">{t("OFFERE.TEXT13")}</button>
                        </div>
                      </div>
                    })
                  }

                </div>
              </div>
            </div>
          </div>
        </section>

      </section>
      <Footer />
    </>

  );
};

export default OffersJsx;
